<template>
  <div>
    <div class="breadCrumb" style="display: flex;">
      <div @click="tabBreadCrumb(index)" v-for="(item, index) in breadCrumb" :key="index"
        :class="index == bread_crumb_selected ? 'bread_crumb_item_selected' : 'bread_crumb_item'">{{ item.name }}
      </div>
    </div>
    <div class="wrapper">
      <div class="header" v-if="bread_crumb_selected !== 3">
        <div v-if="bread_crumb_selected == 0"
          style="display: flex;margin-top: 2px;margin-right: 10px;align-items: center;justify-content: space-between;width: 100%;">
          <div style="display: flex;align-items: center;">
            <div @click="zfbApp_create" class="mybtn" style="margin-right: 10px;">创建应用</div>
            <div @click="tempSet" class="mybtn"
              style="margin-right: 10px;background-color: #f5f5f5;color: black;border: 1px solid #2c72b0aa;padding: 4px 9px;color: #2c72b0;">
              模板设置</div>
          </div>

          <div style="display: flex;align-items: center;">
            <div style="display: flex;margin-top: 2px;">
              <input v-model="zfb_searchValue" type="text" @input="zfb_searchInput"
                style="border-radius: 3px 0 0 3px;border-right: none;border: 1px solid #cccccc;width: 130px;"
                placeholder="">
              <div @click="zfb_search" class="mybtn" style="border-radius: 0 3px 3px 0;">搜索</div>
            </div>
          </div>
        </div>
        <div v-if="bread_crumb_selected == 1"
          style="display: flex;margin-top: 2px;margin-right: 10px;align-items: center;justify-content: space-between;width: 100%;">
          <div style="display: flex;align-items: center;">
            <div @click="wechatApp_create" class="mybtn" style="margin-right: 10px;">创建应用</div>
          </div>
          <div style="display: flex;align-items: center;">
            <div style="display: flex;margin-top: 2px;">
              <input v-model="wechat_searchValue" type="text" @input="wechat_searchInput"
                style="border-radius: 3px 0 0 3px;border-right: none;border: 1px solid #cccccc;width: 130px;"
                placeholder="">
              <div @click="wechat_search" class="mybtn" style="border-radius: 0 3px 3px 0;">搜索</div>
            </div>
          </div>
        </div>
      </div>

      <div class="body">
        <div v-if="bread_crumb_selected == 0" style="padding: 10px;">
          <div v-if="zfb_app_list.length == 0 && !showLoading"
            style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
            暂无
          </div>
          <Table ref="zfb_app" :fields="zfb_fields" :data="zfb_app_list" :searchValue="zfb_searchValue">
            <template v-slot:client="cell">
              <div>
                {{ cell.value == 'wx' ? '微信' : '支付宝' }}
              </div>
            </template>
            <template v-slot:status="cell">
              <!-- <div style="cursor: pointer;user-select: none;display: flex;align-items: center;">
                <div style="color:#2c72b0;font-size: 13px;"
                  :style="{ color: ['#f73131', '#ed7424', '#000000', '#000000', '#206bc4', '#1aad19', '#f73131'][cell.value] }">
                  {{ cell.item.isTemp !== 1 ? ['待商家确认', '待商家授权', '待上传版本', '待提审', '审核中', '审核通过，待发布', '审核驳回'][cell.value] :
                    ''
                  }}
                </div>
                <b-icon v-if="cell.item.isTemp !== 1 && cell.value == 4" style="margin-left: 3px;" icon="clock-fill"
                  variant="primary"></b-icon>
                <b-icon v-if="cell.item.isTemp !== 1 && cell.value == 6" style="margin-left: 3px;"
                  icon="exclamation-circle-fill" variant="danger"></b-icon>
              </div> -->
              <div :id="'popover-target_' + cell.item.id"
                style="cursor: pointer;user-select: none;display: flex;align-items: center;">
                <div style="color:#2c72b0;font-size: 13px;"
                  :style="{ color: ['#f73131', '#ed7424', '#000000', '#000000', '#206bc4', '#1aad19', '#f73131', '#90ee90'][cell.value] }">
                  {{ cell.item.isTemp !== 1 ? ['待商家确认', '待商家授权', '待上传版本', '待提审', '审核中', '审核通过，待发布', '审核驳回', '已上架'][cell.value] :
                    ''
                  }}
                </div>
                <b-icon v-if="cell.item.isTemp !== 1 && cell.value == 4" style="margin-left: 3px;" icon="clock-fill"
                  variant="primary"></b-icon>
                <b-icon v-if="cell.item.isTemp !== 1 && cell.value == 6" style="margin-left: 3px;"
                  icon="exclamation-circle-fill" variant="danger"></b-icon>
              </div>
              <b-popover v-if="cell.value == 6" :target="'popover-target_' + cell.item.id" trigger="hover" title="驳回原因">
                <div v-html="cell.item.rejectReason" style="padding: 3px 0;white-space: pre-wrap;" :key="index">
                </div>
              </b-popover>
            </template>
            <template v-slot:operate="cell">
              <div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap">
                <div class="underline" @click="zfbApp_edit(cell.item)"
                  style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;margin-left: 10px;">
                  <img src="../assets/edit.png" style="width: 22px;height: 22px;display: inline-block;" alt="">
                  <span style="font-size: 13px;">修改</span>
                </div>
                <div class="underline" @click="zfbApp_delete(cell.item)"
                  style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;margin-left: 10px;">
                  <img src="../assets/delete2.png" style="width: 22px;height: 22px;display: inline-block;" alt="">
                  <span style="font-size: 13px;">删除</span>
                </div>
                <div style="margin-left: 10px;" v-if="cell.item.isTemp == 1">
                  <b-dropdown dropleft size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                      <div class="underline"
                        style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;">
                        <img src="../assets/more.png" style="width: 22px;height: 22px;display: inline-block;" alt="">
                        <span style="font-size: 13px;">更多</span>
                      </div>
                    </template>
                    <b-dropdown-item @click="apiFieldApply(cell.item)">申请用户信息权限</b-dropdown-item>
                    <b-dropdown-item @click="auth_share(cell.item)">邀请商家授权</b-dropdown-item>
                  </b-dropdown>
                </div>
                <div style="margin-left: 10px;" v-if="cell.item.isTemp !== 1">
                  <b-dropdown dropleft size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                      <div class="underline"
                        style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;">
                        <img src="../assets/more.png" style="width: 22px;height: 22px;display: inline-block;" alt="">
                        <span style="font-size: 13px;">更多</span>
                      </div>
                    </template>

                    <b-dropdown-item v-if="cell.item.status >= 2"
                      @click="baseinfoModify(cell.item)">编辑小程序基础信息</b-dropdown-item>

                    <!-- <b-dropdown-item v-if="cell.item.getPhoneNumber != 2 || cell.item.getUserInfo != 2" :disabled="cell.item.hasBaseInfo == 0"
                      @click="apiFieldApply(cell.item)">申请用户信息权限</b-dropdown-item> -->
                    <b-dropdown-item v-if="cell.item.status >= 2" :disabled="cell.item.hasBaseInfo == 0"
                      @click="apiFieldApply(cell.item)">申请用户信息权限</b-dropdown-item>
                    <div>
                      <b-dropdown-item v-if="cell.item.status >= 2" @click="uploadApp(cell.item)">上传版本</b-dropdown-item>
                      <b-dropdown-item v-if="cell.item.status >= 2" @click="versionAuditApply(cell.item)" href="#"
                        :disabled="!(cell.item.status === 3 || cell.item.status === 6)">
                        {{ cell.item.status === 4 ? '审核中' : cell.item.status === 5 ? '审核通过' : cell.item.status ===
                          6 ? '重新提交审核' : '提交审核' }}
                        <span v-if="cell.item.status >= 3" style="color: #cccccc;">({{
                          cell.item.status == 3 ? cell.item.last_version : cell.item.version
                        }})</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="cell.item.status >= 2 && cell.item.status === 4"
                        @click="versionAuditCancel(cell.item)">撤回审核</b-dropdown-item>
                      <b-dropdown-item href="#" @click="versionOnline(cell.item)"
                        v-if="cell.item.status === 5">发布上架</b-dropdown-item>
                      <b-dropdown-item href="#" @click="versionOffline(cell.item)"
                        v-if="cell.item.status === 7">下架</b-dropdown-item>
                    </div>
                    <b-dropdown-item :disabled="cell.item.status === 0"
                      @click="auth_share(cell.item)">邀请商家授权</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </template>
          </Table>
        </div>

        <div v-if="bread_crumb_selected == 1" style="padding: 10px;">
          <div v-if="wechat_app_list.length == 0 && !showLoading"
            style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
            暂无
          </div>
          <Table ref="wechat_app" :fields="fields" :data="wechat_app_list" :searchValue="wechat_searchValue">
            <template v-slot:client="cell">
              <div>
                {{ cell.value == 'wx' ? '微信' : '支付宝' }}
              </div>
            </template>

            <template v-slot:operate="cell">
              <div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap">
                <div @click="wechatApp_edit(cell.item)"
                  style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;margin-left: 10px;">
                  <img src="../assets/edit.png" style="width: 22px;height: 22px;display: inline-block;" alt="">
                  <span style="font-size: 13px;">修改</span>
                </div>
                <div @click="wechatApp_delete(cell.item)"
                  style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;margin-left: 10px;">
                  <img src="../assets/delete2.png" style="width: 22px;height: 22px;display: inline-block;" alt="">
                  <span style="font-size: 13px;">删除</span>
                </div>
              </div>
            </template>
          </Table>
        </div>



        <div v-if="showLoading"
          style="display: flex;align-items: center;justify-content: center;padding: 20px 0;background-color: #fff;">
          <img style="margin-right: 5px;" src="../assets/loading.gif" alt="">加载中..
        </div>
      </div>

    </div>
    <div v-if="showAuthModal"
      style="position: fixed;left: 0;right: 0;bottom: 0;top: 0;margin: auto;width: 350px;height: 330px;border-radius: 4px;display: flex;flex-direction: column;align-items: center;background-color: #fff;box-shadow: 0 0 18px 4px #00000022;">
      <span style="padding: 20px 0 11px 0;font-size: 18px;">已发送邀请，请联系商家确认授权
        <button @click="hideAuthModal" type="button" aria-label="Close" class="close"
          style="position: absolute;right: 0;width: 50px;">×</button>
      </span>
      <div
        style="width: 350px;border-top: 0.1px solid #c0c0c0;display: flex;flex-direction: column;align-items: center;padding: 5px 0 0 0;">
        <img :src="qrcode" alt="" style="width:210px;height: 210px;border-radius: 4px;border: none;">
        <span style="padding-bottom: 8px;color: #555555;font-size: 17px;letter-spacing: 1px;">支付宝扫码授权</span>
        <!-- <a class="underline"
          style="width: 100%;text-align: left;padding-bottom: 5px;font-size: 14px;cursor: pointer;text-overflow: ellipsis;overflow: hidden;-webkit-line-clamp: 2 ;-webkit-box-orient: vertical;display: -webkit-box;"
          :href="authShareLink">邀请链接{{ authShareLink }}</a> -->
      </div>

    </div>
    <!-- 图片预览 -->
    <PreviewImages v-model="showPreviewImages" :src="preview_images"></PreviewImages>
    <!-- 微信小程序相关模态弹窗 -->
    <Modal ref="wechatApp_create" @confirm="wechatApp_create_submit"></Modal>
    <Modal ref="wechatApp_edit" @confirm="wechatApp_edit_submit"></Modal>
    <Modal ref="wechatApp_delete" @confirm="wechatApp_delete_submit"></Modal>
    <!-- 支付宝小程序相关模态弹窗 -->
    <Modal ref="zfbApp_edit" @confirm="zfbApp_edit_submit"></Modal>
    <Modal ref="zfbApp_delete" :autoHide="false" @confirm="zfbApp_delete_submit"></Modal>
    <Modal ref="zfbApp_create" id="zfbApp_create" :autoHide="false" @confirm="zfbApp_create_submit" labelWidth="180px">
      <!-- <template v-slot:item="">
        <div style="display: flex;align-items: center;margin: 20px 0;user-select: none;">
          <span style="margin-right: 8px;min-width: 180px;width: 180px;text-align: right;">
            <span style="color:red;margin-right: 5px;"></span>是否将个人支付宝升级为企业支付宝
          </span>
          <b-form-checkbox style="height: 30px;" size="lg" v-model="is_individual" name="" switch> </b-form-checkbox>
        </div>
        <div v-if="is_individual" style="display: flex;align-items: center;margin: 20px 0;user-select: none;">
          <span style="margin-right: 8px;min-width: 180px;width: 180px;text-align: right;">
            <span style="color:red;margin-right: 5px;">*</span>营业执照图片(大小 1.2M以内)
          </span>
          <Upload v-model="license_pic" type="base64" :maxSize="1200000" @error="uploadFail"></Upload>
        </div>
      </template> -->
    </Modal>
    <Modal ref="baseinfoModify" :autoHide="false" @confirm="baseinfoModify_submit"></Modal>
    <Modal ref="apiFieldApply" :autoHide="false" @confirm="apiFieldApply_submit"></Modal>



    <Modal ref="uploadApp" :autoHide="false" @confirm="uploadApp_submit"></Modal>
    <Modal ref="versionOnline" :autoHide="false" @confirm="versionOnline_submit"></Modal>
    <Modal ref="versionOffline" :autoHide="false" @confirm="versionOffline_submit"></Modal>
    <Modal ref="versionAuditCancel" :autoHide="false" @confirm="versionAuditCancel_submit"></Modal>

    <Modal ref="versionAuditApply" :autoHide="false" @confirm="versionAuditApply_submit"></Modal>
    <Modal ref="tempSet" @confirm="tempSet_submit" labelWidth="180px"></Modal>






    <!-- 
    <Modal ref="zfbApp_delete" @confirm="wechatApp_delete_submit"></Modal> -->



    <!-- <Modal ref="modal_businesses_info_delete" @confirm="businesses_info_delete_submit"></Modal>
    <Modal ref="modal_businesses_info_remarks" @confirm="businesses_info_remarks_submit"></Modal> -->


  </div>
</template>
<script>
const utils = require('../utils/utils')
import { BModal } from 'bootstrap-vue'
import PreviewImages from '../components/PreviewImages/PreviewImages'
import Modal from '../components/Modal/Modal'
import Table from '../components/Table/Table'
import Select from '../components/Select/Select'
import Upload from '../components/Upload/Upload'
import QRCode from 'qrcode'
export default {
  name: 'Order',
  components: {
    'b-modal': BModal,
    'PreviewImages': PreviewImages,
    'Modal': Modal,
    'Table': Table,
    'Select': Select,
    'Upload': Upload
  },
  data() {
    return {
      tab: [
        { name: '添加师傅', status: 0, count: 0 },
        { name: '邀请师傅', status: 0, count: 0 }
      ],
      breadCrumb: [
        { name: '支付宝小程序' },
        { name: '微信小程序' }
      ],
      bread_crumb_selected: 0,
      selected: 0,
      showLoading: false,
      preview_images: '',
      showPreviewImages: false,


      wechat_app_list: [],
      wechat_searchValue: '',
      zfb_app_list: [],
      zfb_searchValue: '',
      fields: [
        { key: 'id', label: '编号', width: '70px' },
        { key: 'name', label: '小程序名称', width: '200px' },
        { key: 'appid', label: 'appid', width: '200px' },
        { key: 'secret', label: 'secret', width: '300px' },
        { key: 'client', label: '平台', width: '100px' },
        { key: 'createTime', label: '创建时间' },
        { key: 'operate', label: '操作' }
      ],
      zfb_fields: [
        { key: 'id', label: '编号', width: '70px' },
        { key: 'app_name', label: '小程序名称', width: '180px' },
        { key: 'appid', label: 'appid', width: '160px' },
        { key: 'cert_name', label: '企业名称', width: '150px' },
        { key: 'contact_name', label: '商家联系人', width: '100px' },
        { key: 'contact_phone', label: '商家电话', width: '120px' },
        { key: 'status', label: '应用状态', width: '120px' },
        // { key: 'version_status', label: '版本状态', width: '100px' },
        { key: 'createTime', label: '创建时间', width: '100px' },
        { key: 'operate', label: '操作' }
      ],
      license_pic: '',
      is_individual: false,
      tempInfo: {},
      authShareLink: null,
      qrcode: null,
      showAuthModal: false
    };
  },
  mounted() {
    // 组件挂载完成回调，处理逻辑
    this.zfb_app_get()


  },
  created() {

  },
  methods: {
    tabClick(index) {
      this.selected = index
    },
    tabBreadCrumb(index) {
      this.bread_crumb_selected = index
      if (index == 0) {
        this.zfb_app_get()

      } else if (index == 1) {
        this.wechat_app_get()
      }
    },
    async auth_share(item) {
      console.log('授权邀请的appid', item.appid)
      this.showAuthModal = true;
      this.post('zfb_auth_share', { appid: item.appid, host: window.webConfig.host }).then(async ({ taskPageUrl, link, qrcode }) => {
        this.$toast({
          title: taskPageUrl ? '已发送邀请，待商家授权' : '应用授权邀约数量超限，可通过扫码方式进行授权'
        })
        
        if (taskPageUrl) { //推送成功
          this.qrcode = await QRCode.toDataURL(taskPageUrl);
        } else {  //需要手动扫码授权选择授权应用
          this.qrcode = await QRCode.toDataURL(qrcode);
          console.log('授权的链接',qrcode)
        }
        // this.authShareLink = link;
      })
    },
    hideAuthModal() {
      this.showAuthModal = false
    },
    tempSet() {
      let { isvAppid, privateKey, alipayPublicKey, encryptKey, appid, app_auth_token } = this.tempInfo;
      this.$refs.tempSet.show({
        type: 'form',
        title: '模板设置',
        data: [
          { name: 'isvAppid', label: '第三方应用appId', value: isvAppid, placeholder: '', required: true },
          { name: 'privateKey', label: '私钥(privateKey)', type: 'textarea', height: '100px', value: privateKey, placeholder: '', required: true },
          { name: 'alipayPublicKey', label: '支付宝公钥(alipayPublicKey)', type: 'textarea', height: '100px', value: alipayPublicKey, placeholder: '', required: true },
          { name: 'encryptKey', label: 'AES密钥(encryptKey)', value: encryptKey, placeholder: '', required: true },
          { name: 'appid', label: '模板appid', value: appid, placeholder: '', required: true },
          { name: 'app_auth_token', label: '模板授权token', value: app_auth_token, placeholder: '', required: true },
        ]
      })
    },
    tempSet_submit(data) {
      console.log('模板设置发送', data)
      this.post('tempSet', { ...data }).then(res => {
        this.$toast({
          title: '模板设置成功'
        })
        this.tabBreadCrumb(0)
      })
    },
    uploadApp(item) {
      this.$refs.uploadApp.show({
        type: 'query',
        title: '版本上传',
        content: '是否确认上传',
        data: [
          { name: 'appid', label: '应用appid', value: item.appid, required: true, disabled: true }
        ]
      })
    },
    uploadApp_submit(data) {
      this.$refs.uploadApp.showLoading()
      this.post('zfb_app_upload', { ...data }).then(res => {
        this.$refs.uploadApp.hideLoading()
        this.$refs.uploadApp.hide()
        this.$toast({
          title: '版本上传成功'
        })
        this.tabBreadCrumb(0)
      }).catch(err => {
        this.$refs.uploadApp.hideLoading()
        this.$refs.uploadApp.hide()
      })
    },
    versionAuditApply(item) {
      this.$refs.versionAuditApply.show({
        type: 'form',
        title: '提交审核',
        content: '是否提交审核',
        data: [
          { name: 'appid', label: '小程序名称', value: item.app_name, required: true, disabled: true },
          { name: 'appid', label: 'appid', value: item.appid, required: true, disabled: true },
          { name: 'app_logo', label: '小程序logo', type: 'image', value: item.app_logo, placeholder: '', required: false },
          { name: 'app_slogan', label: '小程序简介', type: 'textarea', height: '100px', value: item.app_slogan, placeholder: '请填写版本描述 30-255字以内', required: false },
          { name: 'app_desc', label: '小程序描述', type: 'textarea', height: '100px', value: item.app_desc, placeholder: '请填写版本描述 30-255字以内', required: false },

          { name: 'memo', label: '备注', type: 'textarea', height: '100px', value: item.memo, placeholder: '选填', required: false },

          { name: 'version_desc', label: '版本描述', type: 'textarea', height: '100px', value: item.version_desc || this.$store.get('version_desc'), placeholder: '请填写版本描述 30-255字以内', required: true },
        ]
      })

    },

    versionAuditApply_submit(data) {
      if (data.version_desc.length < 30) {
        this.$toast({ title: '版本描述必须大于30个字符' })
        return
      } else {
        this.$store.set('version_desc', data.version_desc)
      }
      this.$refs.versionAuditApply.showLoading()

      this.post('zfb_app_versionAudit', { ...data }).then(res => {
        this.$refs.versionAuditApply.hideLoading()
        this.$refs.versionAuditApply.hide()
        this.$toast({
          title: '已提交审核'
        })
        this.tabBreadCrumb(0)
      }).catch(err => {
        this.$refs.versionAuditApply.hideLoading()
        this.$refs.versionAuditApply.hide()
      })
    },


    versionAuditCancel(item) {
      this.$refs.versionAuditCancel.show({
        type: 'query',
        title: '撤回审核',
        content: '是否确认撤回',
        data: [
          { name: 'appid', label: '应用appid', value: item.appid, required: true, disabled: true }
        ]
      })
    },
    versionAuditCancel_submit(data) {
      this.$refs.versionAuditCancel.showLoading()
      this.post('zfb_app_versionAuditCancel', { ...data }).then(res => {
        this.$refs.versionAuditCancel.hideLoading()
        this.$refs.versionAuditCancel.hide()
        this.$toast({
          title: '操作成功'
        })
        this.tabBreadCrumb(0)
      }).catch(err => {
        this.$refs.versionAuditCancel.hideLoading()
        this.$refs.versionAuditCancel.hide()
      })
    },


    versionOnline(item) {
      this.$refs.versionOnline.show({
        type: 'query',
        title: '发布小程序',
        content: '是否确认发布',
        data: [
          { name: 'appid', label: '应用appid', value: item.appid, required: true, disabled: true }
        ]
      })
    },
    versionOnline_submit(data) {
      this.$refs.versionOnline.showLoading()
      console.log('发布小程序', data)
      this.post('zfb_app_versionOnline', { ...data }).then(res => {
        this.$refs.versionOnline.hideLoading()
        this.$refs.versionOnline.hide()
        this.$toast({
          title: '上架成功'
        })
        this.tabBreadCrumb(0)
      }).catch(err => {
        this.$refs.versionOnline.hideLoading()
        this.$refs.versionOnline.hide()
      })
    },

    versionOffline(item) {
      this.$refs.versionOffline.show({
        type: 'query',
        title: '下架小程序',
        content: '是否确认下架',
        data: [
          { name: 'appid', label: '应用appid', value: item.appid, required: true, disabled: true }
        ]
      })
    },
    versionOffline_submit(data) {
      this.$refs.versionOffline.showLoading()
      this.post('zfb_app_versionOffline', { ...data }).then(res => {
        this.$refs.versionOffline.hideLoading()
        this.$refs.versionOffline.hide()
        this.$toast({
          title: '下架成功'
        })
        this.tabBreadCrumb(0)
      }).catch(err => {
        this.$refs.versionOffline.hideLoading()
        this.$refs.versionOffline.hide()
      })
    },

    zfbApp_delete(item) {
      this.$refs.zfbApp_delete.show({
        type: 'query',
        title: '删除应用',
        content: '是否确认删除',
        data: [
          { name: 'id', label: '编码', value: item.id, required: true, disabled: true }
        ]
      })
    },
    zfbApp_delete_submit(data) {
      this.$refs.zfbApp_delete.showLoading()
      this.post('zfb_app_delete', { ...data }).then(res => {
        this.$refs.zfbApp_delete.hideLoading()
        this.$refs.zfbApp_delete.hide()
        this.$toast({
          title: '删除成功'
        })
        this.tabBreadCrumb(0)
      }).catch(err => {
        this.$refs.zfbApp_delete.hideLoading()
        this.$refs.zfbApp_delete.hide()
      })
    },


    uploadFail() {
      this.$toast({
        title: '图片大小超过2M，请重新选择'
      })
    },
    zfb_app_get() {
      this.zfb_app_list = [];
      this.showLoading = true;
      let bread_crumb_selected = this.bread_crumb_selected;
      this.post('zfb_app_get', {}).then(({ list, tempInfo }) => {
        console.log(list)
        setTimeout(() => {
          if (bread_crumb_selected !== this.bread_crumb_selected) return false;
          this.zfb_app_list = list
          this.tempInfo = tempInfo
          this.showLoading = false
        }, 100);
      })
    },
    zfb_search() {
      this.$refs.wechat_app.search({
        value: this.wechat_searchValue,
        fields: ['name', 'appid']
      })
    },
    zfbApp_create() {
      console.log('zfbApp_create')
      this.$refs.zfbApp_create.show({
        type: 'form',
        title: '创建应用',
        data: [
          { name: 'app_name', label: '小程序名称', value: null, placeholder: '小程序名称', required: true },
          { name: 'alipay_account', label: '商家支付宝账号', value: null, placeholder: '商家登录支付宝的邮箱帐号或手机号', required: true },
          { name: 'contact_name', label: '商家联系人名称', value: null, placeholder: '', required: true },
          { name: 'contact_phone', label: '商家联系人手机电话', value: null, placeholder: '', required: true, maxlength: 11 },
          { name: 'cert_no', label: '营业执照号码', value: null, placeholder: '', required: true },
          { name: 'cert_name', label: '营业执照企业名称', value: null, placeholder: '如果是无主体名称个体工商户，则填 个体户+法人姓名', required: true },
          { name: 'legal_personal_name', label: '法人姓名', value: null, placeholder: '', required: true },
          { name: 'license_valid_date', label: '营业执照有效期', type: 'date', value: null, placeholder: '', required: false },
          { name: 'first_license_pic', label: '营业执照照片', type: 'image', value: null, placeholder: '', required: false, },
          { name: 'out_door_pic', label: '门头照', type: 'image', value: null, placeholder: '', required: false },
          { name: 'first_special_license_pic', label: '特殊资质图片1', type: 'image', value: null, placeholder: '', required: false },
          { name: 'second_special_license_pic', label: '特殊资质图片2', type: 'image', value: null, placeholder: '', required: false },
          { name: 'third_special_license_pic', label: '特殊资质图片3', type: 'image', value: null, placeholder: '', required: false },
        ]
      })
    },
    zfbApp_create_submit(data) {
      data.license_pic = this.license_pic
      data.is_individual = this.is_individual
      console.log('提交的数据', data)
      // this.$refs.zfbApp_create.showLoading()
      this.post('zfb_app_create', { ...data }).then((res) => {
        console.log(res)
        this.$refs.zfbApp_create.hideLoading()
        this.$refs.zfbApp_create.hide()
        this.$toast({ title: '创建成功' })
        this.tabBreadCrumb(0)
        console.log(res)
      }).catch(res => {
        setTimeout(() => {
          this.$refs.zfbApp_create.hideLoading()
        }, 100);
      })

    },

    baseinfoModify(item) {
      this.$refs.baseinfoModify.show({
        type: 'form',
        title: '小程序基础信息',
        data: [
          { name: 'app_name', label: '小程序名称', value: item.app_name, placeholder: '', required: true, disabled: true },
          { name: 'appid', label: 'appid', value: item.appid, placeholder: '小程序名称', required: true, disabled: true, hide: true },
          { name: 'app_logo', label: '小程序logo', type: 'image', value: item.app_logo, placeholder: '', required: true },
          { name: 'app_slogan', label: '小程序简介', type: 'textarea', height: '100px', value: item.app_slogan, placeholder: '10~32个字符，一个中文两个字符。', required: true, maxlength: 32 },
          { name: 'app_desc', label: '小程序描述', type: 'textarea', height: '100px', value: item.app_desc, placeholder: '20-400个字符，一个中文两个字符。', required: true },
          { name: 'service_phone', label: '客服电话', value: item.service_phone, placeholder: '', required: true },
          { name: 'service_email', label: '客服邮箱', value: item.service_email, placeholder: '', required: true },

        ]
      })
    },
    baseinfoModify_submit(data) {
      this.$refs.baseinfoModify.showLoading()
      this.post('zfb_app_baseinfoModify', { ...data }).then((res) => {
        console.log(res)
        this.$refs.baseinfoModify.hideLoading()
        this.$refs.baseinfoModify.hide()
        this.$toast({ title: '设置成功' })
        this.tabBreadCrumb(0)
        console.log(res)
      }).catch(res => {
        setTimeout(() => {
          this.$refs.baseinfoModify.hideLoading()
        }, 100);
      })

    },

    apiFieldApply(item) {
      let applyUrl = `https://open.alipay.com/dev/workspace/package-apply/isv/apply?packageCode=20180927110154092444&isvAppId=${this.tempInfo.isvAppid}&appType=isvApp`
      window.open(applyUrl); //在新窗口打开第三方网站
      return
      this.$refs.apiFieldApply.show({
        type: 'form',
        title: '申请用户信息权限',
        data: [
          { name: 'app_name', label: '小程序名称', value: item.app_name, placeholder: '', required: true, disabled: true },
          { name: 'appid', label: 'appid', value: item.appid, placeholder: '', required: true, disabled: true },
          { name: 'video', label: '视频', type: 'video', value: item.video, placeholder: '', required: false },
          { name: 'picture_1', label: '图片1', type: 'image', value: null, placeholder: '', required: false },
          { name: 'picture_2', label: '图片2', type: 'image', value: null, placeholder: '', required: false },
          { name: 'picture_3', label: '图片3', type: 'image', value: null, placeholder: '', required: false },
          { name: 'picture_4', label: '图片4', type: 'image', value: null, placeholder: '', required: false },
          { name: 'picture_5', label: '图片5', type: 'image', value: null, placeholder: '', required: false },
        ]
      })
    },
    apiFieldApply_submit(data) {
      this.$refs.apiFieldApply.showLoading()
      this.post('zfb_app_apiFieldApply', { ...data }).then((res) => {
        console.log(res)
        this.$refs.apiFieldApply.hideLoading()
        this.$refs.apiFieldApply.hide()
        this.$toast({ title: '设置成功' })
        this.tabBreadCrumb(0)
        console.log(res)
      }).catch(res => {
        setTimeout(() => {
          this.$refs.apiFieldApply.hideLoading()
        }, 100);
      })

    },


    zfbApp_edit(item) {
      console.log('编辑应用', item)
      this.$refs.zfbApp_edit.show({
        type: 'form',
        title: '编辑应用信息',
        data: [
          { name: 'appid', label: 'appid', value: item.appid, placeholder: '小程序名称', required: true, disabled: true },
          { name: 'app_name', label: '应用名称', value: item.app_name, placeholder: '小程序名称', required: true, disabled: true },
          { name: 'alipay_account', label: '商家支付宝账号', value: item.alipay_account, placeholder: '商家登录支付宝的邮箱帐号或手机号', required: true, disabled: true },
          { name: 'contact_name', label: '商家联系人名称', value: item.contact_name, placeholder: '', required: true },
          { name: 'contact_phone', label: '商家联系人手机电话', value: item.contact_phone, placeholder: '', required: true, maxlength: 11 },
          { name: 'cert_no', label: '营业执照号码', value: item.cert_no, placeholder: '', required: true },
          { name: 'cert_name', label: '营业执照企业名称', value: item.cert_name, placeholder: '如果是无主体名称个体工商户，则填 个体户+法人姓名', required: true },
          { name: 'legal_personal_name', label: '法人姓名', value: item.legal_personal_name, placeholder: '', required: true },
          { name: 'license_valid_date', label: '营业执照有效期', type: 'date', value: item.license_valid_date, placeholder: '', required: false },
          { name: 'first_license_pic', label: '营业执照照片', type: 'image', value: item.first_license_pic, placeholder: '', required: false, },
          { name: 'out_door_pic', label: '门头照', type: 'image', value: item.out_door_pic, placeholder: '', required: false },
          { name: 'first_special_license_pic', label: '特殊资质图片1', type: 'image', value: item.first_special_license_pic, placeholder: '', required: false },
          { name: 'second_special_license_pic', label: '特殊资质图片2', type: 'image', value: item.second_special_license_pic, placeholder: '', required: false },
          { name: 'third_special_license_pic', label: '特殊资质图片3', type: 'image', value: item.third_special_license_pic, placeholder: '', required: false }

        ]
      })
    },
    zfbApp_edit_submit(data) {
      console.log('提交的数据', data)
      this.post('zfb_app_edit', { ...data }).then(({ data }) => {
        this.$toast({ title: '修改成功' })
        this.tabBreadCrumb(0)
      })
    },


    /**获取微信小程序列表*/
    wechat_app_get() {
      this.wechat_app_list = [];
      this.showLoading = true;
      let bread_crumb_selected = this.bread_crumb_selected;
      this.post('wechat_app_get', {}).then(({ list }) => {
        setTimeout(() => {
          if (bread_crumb_selected !== this.bread_crumb_selected) return false;
          this.wechat_app_list = list
          this.showLoading = false
        }, 100);
      })
    },
    wechat_searchInput(e) {
      if (!this.wechat_searchValue) {
        this.wechat_search()
      }
    },
    wechat_search() {
      this.$refs.wechat_app.search({
        value: this.wechat_searchValue,
        fields: ['name', 'appid', 'secret']
      })
    },
    wechatApp_create() {
      console.log('wechatApp')
      this.$refs.wechatApp_create.show({
        type: 'form',
        title: '添加应用',
        data: [
          { name: 'name', label: '应用名称', value: null, placeholder: '小程序名称', required: true },
          { name: 'appid', label: 'appid', value: null, placeholder: '', required: true },
          { name: 'secret', label: 'secret', value: null, placeholder: '', required: true },
          { name: 'tmp_coupon', label: '优惠券领取通知', value: null, placeholder: '请输入模板id', required: true },
          { name: 'tmp_match', label: '匹配成功通知', value: null, placeholder: '请输入模板id', required: true }
        ]
      })
    },
    wechatApp_create_submit(data) {
      this.post('wechat_app_create', { ...data }).then(({ data }) => {
        this.$toast({ title: '添加成功' })
        this.tabBreadCrumb(1)
      })
    },
    wechatApp_edit(item) {
      console.log('编辑应用')
      this.$refs.wechatApp_edit.show({
        type: 'form',
        title: '编辑应用信息',
        data: [
          { name: 'id', label: '编码', value: item.id, required: true, disabled: true },
          { name: 'name', label: '应用名称', value: item.name, placeholder: '小程序名称', required: true },
          { name: 'appid', label: 'appid', value: item.appid, placeholder: '', required: true },
          { name: 'secret', label: 'secret', value: item.secret, placeholder: '', required: true },
          { name: 'tmp_coupon', label: '优惠券领取通知', value: item.tmp_coupon, placeholder: '请输入模板id', required: true },
          { name: 'tmp_match', label: '匹配成功通知', value: item.tmp_match, placeholder: '请输入模板id', required: true }
        ]
      })
    },
    wechatApp_edit_submit(data) {
      this.post('wechatApp_edit', { ...data }).then(({ data }) => {
        this.$toast({ title: '修改成功' })
        this.tabBreadCrumb(1)
      })
    },
    wechatApp_delete(item) {
      this.$refs.wechatApp_delete.show({
        type: 'query',
        title: '删除应用',
        content: '是否确认删除？',
        data: [
          { name: 'id', label: '编码', value: item.id, required: true, disabled: true }
        ]
      })
    },
    wechatApp_delete_submit(data) {
      this.post('wechatApp_delete', { ...data }).then(() => {
        this.$toast({ title: '删除成功' })
        this.tabBreadCrumb(1)
      })
    },











    // zfb_app_get() {
    //   this.zfb_app_list = [];
    //   this.showLoading = true;
    //   let bread_crumb_selected = this.bread_crumb_selected;
    //   this.post('zfb_app_get', {}).then(({ list }) => {
    //     setTimeout(() => {
    //       if (bread_crumb_selected !== this.bread_crumb_selected) return false;
    //       this.zfb_app_list = list
    //       this.showLoading = false
    //     }, 100);
    //   })
    // },



    // zfb_searchInput(e) {
    //   console.log('zfb_searchInput', this.zfb_searchValue)
    //   if (!this.zfb_searchValue) {
    //     this.zfb_search()
    //   }
    // },

    // zfb_search() {
    //   console.log('开始搜索')
    //   this.$refs.zfb_app.search({
    //     value: this.zfb_searchValue,
    //     fields: ['name', 'appid', 'secret']
    //   })
    // },

    previewImages(e) {
      this.showPreviewImages = true
      this.preview_images = [e]
    },

  }
}
</script>
<style scoped >
.wrapper {
  color: #333;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #dddddd;
}

.header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #dddddd;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 10px 15px;
  font-size: 12.5px;
  font-family: "Microsoft YaHei", SimSun;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dddddd;
}

.bread_crumb_item {
  cursor: pointer;
  border-bottom: 2px solid #fff;
  padding: 10px 15px 8px 15px;
  color: #206bc4;
  font-size: 14px;
  margin-right: 4px;

}

.bread_crumb_item_selected {
  cursor: pointer;
  border-bottom: 2px solid #206bc4;
  padding: 10px 15px 8px 15px;
  border-radius: 5px 5px 0 0;
  color: #fff;
  background: #206bc4;
  font-size: 14px;
  margin-right: 4px;

}

.bread_crumb_item_selected:hover {
  background: #206bc4;
}

.bread_crumb_item:hover {
  background: #eeeeee;
}

.body {}

.tab {
  background-color: white;
  padding: 5px 10px;
  font-size: 11.5px;
  cursor: pointer;
  touch-action: manipulation;
  user-select: none;

}

.tab-select {
  color: white;
  background-color: #1f68be;
}

.tab:hover {
  background: #e6e6e6;
  color: black;
}

.tab:active {
  background: #e6e6e6;
  color: black;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.tab-border-left {
  border-left: 1px solid #cccccc;
}

.mybtn {
  background-color: #2c72b0;
  padding: 5px 10px;
  font-size: 11.5px;
  cursor: pointer;
  touch-action: manipulation;
  color: #ffffff;
  user-select: none;
  border-radius: 3px;
}

.mybtn:hover {
  background-color: #286090;
  color: #ffffff;
}

.mybtn:active {
  background-color: #1d5280;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}


.btn2 {
  background-color: #e8e8e8;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  padding: 5px 10px;
  font-size: 11.5px;
  cursor: pointer;
  touch-action: manipulation;
  border: 1px solid #555555;
  color: #000000;
  border-radius: 3px;
  user-select: none;
}

.btn2:hover {
  background-color: #cccccc;
}

.btn2:active {
  background-color: #aaaaaa;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}



.btn3 {
  background-color: #f0ad4e;
  padding: 8px 17px;
  font-size: 14px;
  cursor: pointer;
  touch-action: manipulation;
  color: #ffffff;
  border-radius: 3px;
}

.btn3:hover {
  background-color: #e09b3c;
  color: #ffffff;
}

input {
  outline: none;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  border: 1px solid #cccccc;
}

input:focus {
  border: 1px solid rgb(56, 115, 192) !important;
}

.float {
  /* float: left; */
  /* min-width: 25%; */
  height: 100%;
  display: flex;
  padding: 10px 0;
}


.btn8 {
  position: absolute;
  right: 50px;
  bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #206bc4;
  z-index: 888;
  color: white;
  padding: 10px 30px;
  border-radius: 7px;
  cursor: pointer;
  font-size: 17px;
}

.underline:hover {
  text-decoration-color: #0056b3;
  text-decoration-line: underline;
}


.btn8:hover {
  background-color: #2f62a1;
  color: white;
}
</style>
<style>
.btn-lg,
.btn-group-lg>.btn {
  padding: 0;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  box-shadow: none;
  border: none;
  padding: 0 !important;
  margin: 0 !important;
}

.btn {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  transition: none !important;
}
</style>