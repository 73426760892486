<template>
    <div style="padding-bottom: 20px;">
        <div class="breadCrumb" style="display: flex;">
            <div @click="tabBreadCrumb(index)" v-for="(item, index) in breadCrumb" :key="index"
                :class="index == bread_crumb_selected ? 'bread_crumb_item_selected' : 'bread_crumb_item'">{{ item.name }}
            </div>
        </div>
        <div class="wrapper">
            <!-- 微信小程序通知 -->
            <div v-if="bread_crumb_selected == 0" class="body" style="font-size: 14px;">
                <!-- 测试 -->
                <div style="display: flex;align-items: center;margin-top: 17px;">
                    <div
                        style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
                        <div>选择模板</div>
                    </div>
                    <div style="display: flex;align-items: center;width: 40%;">
                        <Select placeholder="" :showAll="false" @change="selectTemplate" style="width: 100%;z-index: 13;"
                            v-model="template_selected" :options="tmp_list"></Select>
                    </div>
                </div>
                <div style="display: flex;align-items: center;margin-top: 17px;">
                    <div
                        style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
                        <div>选择城市</div>
                    </div>
                    <div style="display: flex;align-items: center;width: 40%;">
                        <Select placeholder="全部" :showAll="false" @change="selectCity" style="width: 100%;z-index: 12;"
                            v-model="city_selected" :options="cities"></Select>
                    </div>
                </div>
                <div v-if="notice_selected !== null && notice_selected !== undefined">
                    <div v-for="(item, index) in notice[notice_selected].data" :key="index"
                        style="display: flex;margin-top: 17px;">
                        <div
                            style="width:16.6%;display: flex;justify-content: flex-end;font-weight: 700;padding-right: 25px;margin-top: 4px;">
                            <i style="color:rgb(219, 94, 90);margin-right: 10px;">*</i>
                            <div>{{ item.name }}</div>
                        </div>
                        <input v-if="item.key.indexOf('thing') !== -1" v-model="item.value" style="height: 34px;width: 40%;"
                            maxlength="20" type="text">
                        <div v-else-if="item.key.indexOf('time') !== -1">
                            <b-form-datepicker id="example-datepicker" v-model="item.value" class="mb-2" placeholder="选择日期"
                                style="font-size: 14px;"></b-form-datepicker>
                        </div>
                    </div>
                </div>

                <div
                    style="background-color: #f5f5f5;width: 100%;display: flex;align-items: center;justify-content: center;margin-top: 14px;padding: 14px 0;">
                    <b-button @click="sendNotice" class="mybtn"
                        style="width: 30%;padding: 8px;font-size: 14px;">发送通知</b-button>
                </div>
            </div>

            <!-- 支付宝小程序通知 -->
            <div v-if="bread_crumb_selected == 1" class="body" style="font-size: 14px;">
                <!-- 测试 -->
                <div style="display: flex;align-items: center;margin-top: 17px;">
                    <div
                        style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
                        <div>选择模板</div>
                    </div>
                    <div style="display: flex;align-items: center;width: 40%;">
                        <Select :showAll="false" placeholder="" @change="zfb_selectTemplate"
                            style="width: 100%;z-index: 13;" v-model="zfb_template_selected"
                            :options="zfb_tmp_list"></Select>
                    </div>
                </div>
                <div style="display: flex;align-items: center;margin-top: 17px;">
                    <div
                        style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
                        <div>选择城市</div>
                    </div>
                    <div style="display: flex;align-items: center;width: 40%;">
                        <Select placeholder="全部" :showAll="false" @change="zfb_selectCity" style="width: 100%;z-index: 12;"
                            v-model="zfb_city_selected" :options="zfb_cities"></Select>
                    </div>
                </div>
                <div v-if="zfb_notice_selected !== null && zfb_notice_selected !== undefined">
                    <div v-for="(item, index) in zfb_notice[zfb_notice_selected].data" :key="index"
                        style="display: flex;margin-top: 17px;">
                        <div
                            style="width:16.6%;display: flex;justify-content: flex-end;font-weight: 700;padding-right: 25px;margin-top: 4px;">
                            <i style="color:rgb(219, 94, 90);margin-right: 10px;">*</i>
                            <div>{{ item.name }}</div>
                        </div>
                        <Div v-if="item.key.indexOf('keyword3') !== -1">
                            <b-form-datepicker id="example-datepicker" v-model="item.value" class="mb-2" placeholder="选择日期"
                                style="font-size: 14px;"></b-form-datepicker>
                        </div>
                        <input v-else-if="item.key.indexOf('keyword') !== -1" v-model="item.value"
                            style="height: 34px;width: 40%;" maxlength="20" type="text">

                    </div>
                </div>

                <div
                    style="background-color: #f5f5f5;width: 100%;display: flex;align-items: center;justify-content: center;margin-top: 14px;padding: 14px 0;">
                    <b-button @click="zfb_sendNotice" class="mybtn"
                        style="width: 30%;padding: 8px;font-size: 14px;">发送通知</b-button>
                </div>
            </div>
            <!-- 公众号通知绑定 -->

            <div v-if="bread_crumb_selected == 2" class="body" style="font-size: 14px;">
                <div style="display: flex;align-items: center;margin-top: 17px;padding-bottom: 20px;">
                    <div
                        style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
                        <div>扫码绑定</div>
                    </div>
                    <img :src="qrcode"
                        style="width: 125px;height: 125px;border-radius: 4px;background-color: #f5f5f5;border: 1px solid #cccccc;"
                        alt="">
                </div>

                <!--                 
                <div style="display: flex;align-items: center;margin-top: 22px;padding-bottom: 20px;justify-content: center;flex-direction: column;width: 150px;margin-left: 50px;">
                    <img style="width: 135px;height: 135px;border-radius: 3px;background-color: #f5f5f5;" src="" alt="">
                    <div style="color: #666666;font-size: 22px;margin-top: 10px;">
                     扫码绑定
                    </div>
                </div> -->
            </div>

        </div>
    </div>
</template>
<script>
const utils = require('../utils/utils')
import QRCode from 'qrcode'
import { BModal } from 'bootstrap-vue'
import Upload from '../components/Upload/Upload'
import Select from '../components/Select/Select'

export default {
    components: {
        'b-modal': BModal,
        'Upload': Upload,
        Select
    },
    data() {
        return {
            breadCrumb: [
                { name: '微信小程序通知' },
                { name: '支付宝小程序通知' },
                { name: '通知提醒' }
            ],
            bread_crumb_selected: 0,
            config: {},
            uploading: false,
            upload_process: 0,
            options_city: [
                { value: 0, text: '广东' },
                { value: 1, text: '深圳' }
            ],
            options_city_selected: 0,
            options_tmp_selected: 0,
            help: [],
            partnership: [],
            notice: [],
            zfb_notice: [],
            template_selected: null,
            zfb_template_selected: null,
            tmp_list: [],
            zfb_tmp_list: [],
            cities: [],
            zfb_cities: [],
            city_selected: null,
            zfb_city_selected: null,
            notice_selected: null,
            zfb_notice_selected: null,
            qrcode: '',
            system_config: {},
        };
    },
    mounted() {
        this.getNotice()
        this.getConfig()
    },
    methods: {
        tabBreadCrumb(index) {
            console.log(index)
            this.bread_crumb_selected = index
            if (index == 0) {
                this.getNotice()
            } else if (index == 1) {
                this.getZfbNotice()
                // this.getHelp()
            } else if (index == 3) {
                // this.partnership_get()
            }
        },
        getConfig() {
          this.get('config_get').then(({ config }) => {
            console.log(config)
            this.system_config = config
            let url = `${window.webConfig.host}/redirect/wechat_redirect_uri?token=${this.$store.get('token')}&appid=${this.system_config.wxgzh_appid}&redirect_uri=${window.webConfig.host}`
            console.log("wechat qrcode", url)
            QRCode.toDataURL(url, (err, url) => {
                this.qrcode = url
            })
          })
    },
        getNotice() {
            this.get('notice_get').then(({ notice, tmp_list, cities }) => {
                this.notice = notice
                this.tmp_list = tmp_list
                cities.unshift({
                    text: '全部',
                    value: null
                })
                this.cities = cities
                console.log(notice, tmp_list)
            })
        },
        getZfbNotice() {
            this.get('zfb_notice_get').then(({ notice, tmp_list, cities }) => {
                this.zfb_notice = notice
                this.zfb_tmp_list = tmp_list
                this.zfb_cities = cities
                cities.unshift({
                    text: '全部',
                    value: null
                })
            })
        },
        selectTemplate(e) {
            console.log('模板选择', this.template_selected)
            for (let i in this.notice) {
                if (this.notice[i].tmp_name === this.template_selected) {
                    this.notice_selected = i
                }
            }
        },
        zfb_selectTemplate(e) {
            console.log('模板选择', this.zfb_template_selected)
            for (let i in this.zfb_notice) {
                if (this.zfb_notice[i].tmp_name === this.zfb_template_selected) {
                    this.zfb_notice_selected = i
                }
            }
        },
        selectCity() {
            console.log('城市选择', this.city_selected)
            if (this.notice_selected !== undefined && this.notice_selected !== null) {
                this.notice[this.notice_selected].city = this.city_selected
            }
        },
        zfb_selectCity() {
            console.log('城市选择', this.zfb_city_selected)
            if (this.zfb_notice_selected !== undefined && this.zfb_notice_selected !== null) {
                this.zfb_notice[this.zfb_notice_selected].city = this.zfb_city_selected
            }
        },
        sendNotice() {
            if (this.notice_selected !== undefined && this.notice_selected !== null) {
                let noticeInfo = this.notice[this.notice_selected];
                let { data, city, tmp_name } = noticeInfo;
                this.post('sendNotice', { data, city, tmp_name, client: 'wx' }).then((res) => {
                    this.$toast({ title: '发送成功' })
                })
            }
        },
        zfb_sendNotice() {
            if (this.zfb_notice_selected !== undefined && this.zfb_notice_selected !== null) {
                let noticeInfo = this.zfb_notice[this.zfb_notice_selected];
                let { data, city, tmp_name } = noticeInfo;
                this.post('sendNotice', { data, city, tmp_name, client: 'zfb' }).then((res) => {
                    this.$toast({ title: '发送成功' })

                })
            }
        },








        getHelp() {
            this.get('helpList_get').then(({ help }) => {
                console.log(help)
                this.help = help
            })
        },
        addHelp() {
            this.help.push({
                question: '',
                answer: ''
            })
        },
        /* 合作方公示 */
        partnership_get() {
            this.get('partnership_get').then(({ list }) => {
                this.partnership = list
            })
        },
        partnership_add() {
            this.partnership.push({
                title: '',
                content: '',
                introduction: ''
            })
            console.log(this.partnership)
        },
        partnership_save() {
            console.log('发送的数组', this.partnership)
            this.post('partnership_save', { partnership: this.partnership }).then((res) => {
                this.$toast({ title: '保存成功' })
                this.partnership_get()
            })
        },
        partnership_delete(index) {
            this.partnership.splice(index, 1)
        },
        partnership_move(index) {
            if (index !== 0) {
                let temp = this.partnership[index]
                this.partnership.splice(index - 1, 0, temp)
                this.partnership.splice(index + 1, 1)
            }
            console.log('移动后的数组', this.partnership)
        },

        /* 帮助中心 */
        deleteHelp(index) {
            this.help.splice(index, 1)
        },
        moveUpHelp(index) {
            if (index !== 0) {
                let temp = this.help[index]
                this.help.splice(index - 1, 0, temp)
                this.help.splice(index + 1, 1)
            }

        },
        submit() {
            console.log('config:', this.config)
            this.config.ad_type = this.ad_type;
            this.post('config_set', { config: this.config }).then((res) => {
                console.log('更新返回', res)
                // this.config = config
                this.$toast({ title: '保存成功' })
            })
        },
        submitHelp() {
            this.post('help_set', { help: this.help }).then((res) => {
                console.log('更新返回', res)
                // this.config = config
                this.$toast({ title: '保存成功' })
            })
        },
        submittheme() {
            this.post('theme_set', { config: this.config }).then((res) => {
                console.log('更新返回', res)
                this.$toast({ title: '保存成功' })
            })
        },
        fileChange(e, key) {
            let that = this;
            var files = [];
            that.upload_process = 0
            console.log(key)
            for (let i = 0; i < e.target.files.length; i++) {
                files.push({
                    Bucket: 'cloud-1258469261', /* 填写自己的bucket，必须字段 */
                    Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
                    Key: (Date.now() + i),  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
                    Body: e.target.files[i], /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
                    Prefix: '/',
                    onTaskReady: function (taskId) {
                        /* taskId可通过队列操作来取消上传cos.cancelTask(taskId)、停止上传cos.pauseTask(taskId)、重新开始上传cos.restartTask(taskId) */
                        // console.log(taskId);
                    }
                })
            }
            console.log(files)
            if (files.length !== 0) {
                var filesLength = files.length;
                var success = 0;
                that.uploading = true;
                cos.uploadFiles({
                    files: files,
                    SliceSize: 1024 * 1024 * 10,    /* 设置大于10MB采用分块上传 */
                    onFileFinish: function (err, data, options) {
                        filesLength -= 1
                        success += err ? 0 : 1
                        if (filesLength == 0) {
                            if (success == files.length) {
                                that.upload_process++
                                console.log(`上传成功 ${success}张`)
                            } else {
                                console.log(`上传成功 ${success}张 失败${files.length - success}张`)
                            }
                        }
                        console.log(options.Key + '上传' + (err ? '失败' : '完成'), '剩余', filesLength);
                    },
                }, function (err, data) {
                    console.log(err || data);
                    if (err) {
                        console.error('存储桶配置错误！')
                    } else {
                        console.log('sss', key)
                        let uploadedFiles = [];
                        data.files.forEach((item, i) => {
                            uploadedFiles.push(`https://${data.files[i].data.Location}`)
                        })
                        switch (key) {
                            case 'customer_qr':
                                that.config.customer_qr = uploadedFiles[0];
                                setTimeout(() => {
                                    that.uploading = false
                                }, 1000);
                                console.log(that.config.customer_qr, uploadedFiles[0])
                                break
                        }

                    }
                });
            }

        },
        colorChange(e) {
            console.log(e)
        }
    }
}
</script>
<style scoped >
.wrapper {
    color: #333;
    background-color: #fff;
    border-radius: 0 5px 5px 5px;
    border: 1px solid #dddddd;
    width: 88%;
}

.header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dddddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 15px;
    font-size: 12.5px;
    font-family: "Microsoft YaHei", SimSun;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dddddd;
}

.bread_crumb_item {
    cursor: pointer;
    border-bottom: 2px solid #fff;
    padding: 10px 15px 8px 15px;
    color: #206bc4;
    font-size: 14px;
    margin-right: 4px;

}

.bread_crumb_item_selected {
    cursor: pointer;
    border-bottom: 2px solid #206bc4;
    padding: 10px 15px 8px 15px;
    border-radius: 5px 5px 0 0;
    color: #fff;
    background: #206bc4;
    font-size: 14px;
    margin-right: 4px;

}

.bread_crumb_item_selected:hover {
    background: #206bc4;
}

.bread_crumb_item:hover {
    background: #eeeeee;
}

.body {}

.tab {
    background-color: white;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    user-select: none;

}

.tab-select {
    color: white;
    background-color: #1f68be;
}

.tab:hover {
    background: #e6e6e6;
    color: black;
}

.tab:active {
    background: #e6e6e6;
    color: black;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.tab-border-left {
    border-left: 1px solid #cccccc;
}

.mybtn {
    background-color: #2c72b0;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    user-select: none;
    height: 40px;
}

.mybtn:hover {
    background-color: #286090;
    color: #ffffff;
}

.mybtn:active {
    background-color: #1d5280;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}


.btn2 {
    background-color: #e8e8e8;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    border: 1px solid #555555;
    color: #000000;
    border-radius: 3px;
    user-select: none;
}

.btn2:hover {
    background-color: #cccccc;
}

.btn2:active {
    background-color: #aaaaaa;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

input {
    outline: none;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    border: 1px solid #cccccc;
}

input:focus {
    border: 1px solid rgb(56, 115, 192) !important;
}

.add {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    width: 100%;
    cursor: pointer;
    background-color: #f5f5f5;
}

.add:hover {
    background-color: #eeeeee;
}
</style>


  