<template>
    <div>
        <div class="breadCrumb" style="display: flex;">
            <div @click="tabBreadCrumb(index)" v-for="(item, index) in breadCrumb" :key="index"
                :class="index == bread_crumb_selected ? 'bread_crumb_item_selected' : 'bread_crumb_item'">{{ item.name }}
            </div>
        </div>
        <div class="wrapper">
            <div class="header">
                <div v-if="bread_crumb_selected == 0"
                    style="display: flex;margin-top: 2px;margin-right: 10px;align-items: center;justify-content: space-between;width: 100%;">
                    <div @click="personnel_cooperating_create" class="mybtn" style="margin-right: 10px;">添加技师</div>
                    <div style="display: flex;align-items: center;">
                        <!-- <div @click="personnel_cooperating_create" class="btn" style="margin-right: 10px;;margin-top: 2px;">添加技师</div> -->
                        <div style="display: flex;align-items: center;margin-top: 2px;margin-right: 5px;">
                            <Select @change="citySelectChange" v-model="selected_cities" :options="cities"></Select>
                        </div>
                        <div style="display: flex;margin-top: 2px;">
                            <input v-model="searchValue" type="text" @input="searchInput"
                                style="border-radius: 3px 0 0 3px;border-right: none;border: 1px solid #cccccc;width: 130px;"
                                placeholder="">
                            <div @click="search" class="mybtn" style="border-radius: 0 3px 3px 0;">搜索</div>
                        </div>
                    </div>
                </div>
                <div v-if="bread_crumb_selected == 1" style="display: flex;margin-top: 2px;margin-right: 10px;">
                    <div @click="personnel_info_create" class="mybtn" style="">+ 添加</div>
                </div>
            </div>

            <div class="body">
                <div v-if="bread_crumb_selected == 0" style="padding: 10px;">
                    
                    <Table ref="personnel_cooperating" :fields="fields" :data="personnel_cooperating_list"
                        :searchValue="searchValue" :searchFields="['name', 'phone_number']">
                        <template v-slot:id="cell">
                            <div style="font-weight: 600;height: 100%;display: flex;align-items: center;">{{ cell.value }}
                            </div>
                        </template>
                        <template v-slot:name="cell">
                            <div style="font-weight: 600;height: 100%;display: flex;align-items: center;">{{ cell.value }}
                            </div>
                        </template>

                        <template v-slot:business_city="cell">
                            <div style="">{{ cell.value[1] }}</div>
                        </template>

                        <template v-slot:wechat_qr="cell">
                            <img @click="previewImages(cell.value)"
                                style="width: 95px;height: 95px;padding: 0;margin: 0;cursor: pointer;" :src="cell.value" />
                        </template>
                        <template v-slot:wechat_payment_code="cell">
                            <img @click="previewImages(cell.value)"
                                style="width: 95px;height: 95px;padding: 0;margin: 0;cursor: pointer;" :src="cell.value" />
                        </template>
                        <template v-slot:zfb_payment_code="cell">
                            <img @click="previewImages(cell.value)"
                                style="width: 95px;height: 95px;padding: 0;margin: 0;cursor: pointer;" :src="cell.value" />
                        </template>
                        <template v-slot:id_card="cell">
                            <div @click="modal_viewPrsonnelDetail(cell.item)"
                                style="font-size: 12.5px;color: #206bc4;cursor: pointer;">点击查看</div>
                        </template>
                        <template v-slot:operate="cell">
                            <div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap">
                                <div @click="personnel_cooperating_edit(cell.item)"
                                    style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;margin-left: 10px;">
                                    <img src="../assets/edit.png" style="width: 22px;height: 22px;display: inline-block;"
                                        alt="">
                                    <span style="font-size: 13px;">修改</span>
                                </div>
                                <div @click="personnel_cooperating_delete(cell.item)"
                                    style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;margin-left: 10px;">
                                    <img src="../assets/delete2.png" style="width: 22px;height: 22px;display: inline-block;"
                                        alt="">
                                    <span style="font-size: 13px;">删除</span>
                                </div>
                            </div>
                        </template>
                    </Table>
                    <div v-if="personnel_cooperating_list.length == 0 && !showLoading"
                        style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
                        暂无
                    </div>
                </div>

                <div v-if="bread_crumb_selected == 1" style="padding: 10px;">
                    <div v-if="personnel_info_list.length == 0 && !showLoading"
                        style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
                        暂无
                    </div>
                    <div v-for="(item, index) in personnel_info_list" :key="index">
                        <div v-show="!item.hide" style="border: 1px solid #337ab7;font-size: 13px;margin-bottom: 10px;">
                            <div
                                style="display: flex;justify-content: space-between;align-items: center;background: #337ab7;padding: 9px 5px 7px 5px;color: white;border-radius: 1px;">
                                <div>申请时间：{{ item.createTimeTxt }}</div>
                            </div>
                            <b-container class="bv-example-row">
                                <b-row style="text-align: left;" cols="1" cols-sm="2" cols-md="4" cols-lg="4">
                                    <b-col>
                                        <div class="float" style="min-width: 50%;">姓名：{{ item.name }}</div>
                                    </b-col>
                                    <b-col>
                                        <div class="float" style="min-width: 50%;">申请合作地区：{{ item.business_city[1] }}
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="float" style="min-width: 50%;">身份证信息：{{ item.id_card }}</div>
                                    </b-col>
                                    <b-col>
                                        <div class="float" style="min-width: 50%;">
                                            <div style="font-weight: 600;">联系电话：{{ item.phone_number }}</div>
                                            <div style="font-size: 12px;margin-left: 5px;cursor: pointer;color: #337ab7;">联系
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="float">身份证照片正面：<img @click="previewImages(item.id_card_image_front)"
                                                :src="item.id_card_image_front"
                                                style="width: 88px;height: 88px;cursor: pointer;" alt=""></div>
                                    </b-col>
                                    <b-col>
                                        <div class="float">身份证照片反面：<img @click="previewImages(item.id_card_image_back)"
                                                :src="item.id_card_image_back"
                                                style="width: 88px;height: 88px;cursor: pointer;" alt=""></div>
                                    </b-col>
                                    <b-col>
                                        <div class="float">微信收款码：<img @click="previewImages(item.wechat_payment_code)"
                                                :src="item.id_card_image_front"
                                                style="width: 88px;height: 88px;cursor: pointer;" alt=""></div>
                                    </b-col>
                                    <b-col>
                                        <div class="float">支付宝收款码：<img @click="previewImages(item.zfb_payment_code)"
                                                :src="item.id_card_image_back"
                                                style="width: 88px;height: 88px;cursor: pointer;" alt=""></div>
                                    </b-col>
                                    <b-col>
                                        <div class="float">微信号二维码：<img @click="previewImages(item.wechat_qr)"
                                                :src="item.wechat_qr" style="width: 88px;height: 88px;cursor: pointer;"
                                                alt="">
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="float">其他资质：<img @click="previewImages(item.other)"
                                                :src="item.id_card_image_front"
                                                style="width: 88px;height: 88px;cursor: pointer;" alt=""></div>
                                    </b-col>
                                </b-row>
                            </b-container>
                            <div
                                style="padding: 10px;text-align: left;display: flex;border-top: 1px solid #eeeeee;font-size: 12px;justify-content: space-between;align-items: center;">
                                <div style="display: flex;align-items: center;">
                                    <div style="min-width: 60px;">备注信息：</div>
                                    <div><span style="color: #666666;margin-right: 4px;">{{ item.remarks }} </span>
                                        <span @click="personnel_info_remarks(item)"
                                            style="color: #206bc4;cursor: pointer;">{{
                                                item.remarks ? '(修改备注)' : '(添加备注)' }}</span>
                                    </div>
                                </div>
                                <div @click="personnel_info_delete(item)"
                                    style="color: #fa5151;cursor: pointer;padding-right: 20px;">
                                    删除
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="bread_crumb_selected == 2" style="padding: 10px;">
                    <div v-if="personnel_application_list.length == 0 && !showLoading"
                        style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
                        暂无
                    </div>
                    <div v-for="(item, index) in personnel_application_list" :key="index">
                        <div v-show="!item.hide" style="border: 1px solid #337ab7;font-size: 13px;margin-bottom: 10px;">
                            <div
                                style="display: flex;justify-content: space-between;align-items: center;background: #337ab7;padding: 9px 5px 7px 5px;color: white;border-radius: 1px;">
                                <div>申请时间：{{ item.createTimeTxt }}</div>
                            </div>
                            <b-container class="bv-example-row">
                                <b-row style="text-align: left;" cols="1" cols-sm="2" cols-md="4" cols-lg="4">
                                    <b-col>
                                        <div class="float" style="min-width: 50%;">姓名：{{ item.name }}</div>
                                    </b-col>
                                    <b-col>
                                        <div class="float" style="min-width: 50%;">申请合作地区：{{ item.business_city[1] }}
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="float" style="min-width: 50%;">
                                            <div style="font-weight: 600;">联系电话：{{ item.phone_number }}</div>
                                            <div style="font-size: 12px;margin-left: 5px;cursor: pointer;color: #337ab7;">联系
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="float">微信号二维码：<img @click="previewImages(item.wechat_qr)"
                                                :src="item.wechat_qr" style="width: 88px;height: 88px;cursor: pointer;"
                                                alt="">
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>
                            <div
                                style="padding: 10px;text-align: left;display: flex;border-top: 1px solid #eeeeee;font-size: 12px;justify-content: space-between;align-items: center;">
                                <div style="display: flex;align-items: center;">
                                </div>
                                <div @click="personnel_application_delete(item)"
                                    style="color: #fa5151;cursor: pointer;padding-right: 20px;">
                                    删除
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div v-if="showLoading"
                    style="display: flex;align-items: center;justify-content: center;padding: 20px 0;background-color: #fff;">
                    <img style="margin-right: 5px;" src="../assets/loading.gif" alt="">加载中..
                </div>
            </div>

        </div>

        <!-- 图片预览 -->
        <PreviewImages v-model="showPreviewImages" :src="preview_images"></PreviewImages>
        <!-- 模态弹窗 - 人员列表 -->
        <Modal ref="modal_personnel_cooperating_create" @confirm="modal_personnel_cooperating_create_submit"></Modal>
        <Modal ref="modal_personnel_cooperating_edit" @confirm="modal_personnel_cooperating_edit_submit"></Modal>
        <Modal ref="modal_personnel_cooperating_delete" @confirm="personnel_cooperating_delete_submit"></Modal>
        <Modal ref="modal_viewPrsonnelDetail" :footer="false"></Modal>
        <!-- 模态弹窗 - 信息收录 -->
        <Modal ref="modal_personnel_info_create" @confirm="personnel_info_create_submit"></Modal>
        <Modal ref="modal_personnel_info_delete" @confirm="personnel_info_delete_submit"></Modal>
        <Modal ref="modal_personnel_info_remarks" @confirm="personnel_info_remarks_submit"></Modal>
        <!-- 模态弹窗 - 申请记录 -->
        <Modal ref="modal_personnel_application_delete" @confirm="personnel_application_delete_submit"></Modal>
    </div>
</template>
<script>
const utils = require('../utils/utils')
import { BModal } from 'bootstrap-vue'
import PreviewImages from '../components/PreviewImages/PreviewImages'
import Modal from '../components/Modal/Modal'
import Table from '../components/Table/Table'
import Select from '../components/Select/Select'


export default {
    name: 'Order',
    components: {
        'b-modal': BModal,
        'PreviewImages': PreviewImages,
        'Modal': Modal,
        'Table': Table,
        'Select': Select
    },
    data() {
        return {
            tab: [
                { name: '添加师傅', status: 0, count: 0 },
                { name: '邀请师傅', status: 0, count: 0 }
            ],
            breadCrumb: [
                { name: '人员列表' },
                { name: '信息收录' },
                { name: '申请记录' }
            ],
            bread_crumb_selected: 0,
            selected: 0,
            showLoading: false,
            searchValue: '',
            order_id: '',
            preview_images: '',
            showPreviewImages: false,
            remarks: '',
            personnel_cooperating_list: [],
            personnel_info_list: [],
            personnel_application_list: [],
            fields: [
                { key: 'id', label: '编号', width: '55px' },
                { key: 'name', label: '姓名', width: '100px' },
                { key: 'phone_number', label: '手机号', width: '150px' },
                { key: 'business_city', label: '城市', width: '133px' },
                { key: 'wechat_qr', label: '微信二维码', width: '120px' },
                { key: 'wechat_payment_code', label: '微信收款码', width: '120px' },
                { key: 'zfb_payment_code', label: '支付宝收款码', width: '120px' },
                { key: 'id_card', label: '身份信息', width: 'w-20' },
                { key: 'operate', label: '操作', width: 'w-10' }
            ],
            selected_cities: null,
            cities: []
        };
    },
    mounted() {
        // 组件挂载完成回调，处理逻辑
        this.personnel_cooperating_get()
    },
    methods: {
        tabClick(index) {
            this.selected = index
        },
        tabBreadCrumb(index) {
            console.log(index)
            this.bread_crumb_selected = index
            if (index == 0) {
                this.personnel_cooperating_get()
            } else if (index == 1) {
                this.personnel_info_get()
            } else if (index == 2) {
                this.personnel_application_get()
            }
        },
        /************************ 人员列表 *******************************/
        /**获取服务人员列表*/
        personnel_cooperating_get() {
            this.personnel_cooperating_list = [];
            this.showLoading = true;
            let bread_crumb_selected = this.bread_crumb_selected;
            this.post('personnel_cooperating_get', {}).then(({ list, cities }) => {
                list.forEach(element => {
                    element.business_city = JSON.parse(element.business_city)
                    element.createTimeTxt = utils.formatTime(element.createTime, 'year-month-day hour:minute')
                });
                setTimeout(() => {
                    if (bread_crumb_selected !== this.bread_crumb_selected) return false;
                    this.personnel_cooperating_list = list
                    this.cities = cities
                    this.showLoading = false
                }, 100);

            })

        },
        /**添加服务人员*/
        personnel_cooperating_create(e) {
            this.$refs.modal_personnel_cooperating_create.show({
                type: 'form',
                title: '添加服务人员',
                data: [
                    { name: 'business_city', label: '合作城市', type: 'picker', value: null, placeholder: '', required: true, disabled: false },
                    { name: 'name', label: '姓名', value: null, placeholder: '', required: true },
                    { name: 'phone_number', label: '电话号码', value: null, placeholder: '', required: true, maxlength: 11 },
                    { name: 'id_card', label: '身份证号', value: null, placeholder: '', required: false, maxlength: 18 },
                    { name: 'id_card_image_front', label: '身份证照片正面', value: null, type: 'image', placeholder: '', required: false },
                    { name: 'id_card_image_back', label: '身份证照片反面', value: null, type: 'image', placeholder: '', required: false },
                    { name: 'wechat_qr', label: '个人微信二维码', value: null, type: 'image', placeholder: '', required: false },
                    { name: 'other', label: '其他资质', value: null, type: 'image', placeholder: '', required: false },
                    { name: 'wechat_payment_code', label: '微信收款码', value: null, type: 'image', placeholder: '', required: false },
                    { name: 'zfb_payment_code', label: '支付宝收款码', value: null, type: 'image', placeholder: '', required: false }
                ]
            })
        },
        modal_personnel_cooperating_create_submit(data) {
            console.log('发送的数据', data)
            this.post('personnel_cooperating_create', { ...data }).then(({ data }) => {
                this.$toast({ title: '新建完成' })
                this.personnel_cooperating_get()
            })
        },
        /**编辑服务人员*/
        personnel_cooperating_edit(item) {
            this.$refs.modal_personnel_cooperating_edit.show({
                type: 'form',
                title: '修改服务人员',
                data: [
                    { name: 'id', label: '编码', value: item.id, placeholder: '', required: true, disabled: true },
                    { name: 'business_city', label: '合作城市', type: 'picker', value: item.business_city, placeholder: '', required: true, disabled: false },
                    { name: 'name', label: '姓名', value: item.name, placeholder: '', required: true },
                    { name: 'phone_number', label: '电话号码', value: item.phone_number, placeholder: '', required: true, maxlength: 11 },
                    { name: 'id_card', label: '身份证号', value: item.id_card, placeholder: '', required: false, maxlength: 18 },
                    { name: 'id_card_image_front', label: '身份证照片正面', value: item.id_card_image_front, type: 'image', placeholder: '', required: false },
                    { name: 'id_card_image_back', label: '身份证照片反面', value: item.id_card_image_back, type: 'image', placeholder: '', required: false },
                    { name: 'wechat_qr', label: '个人微信二维码', value: item.wechat_qr, type: 'image', placeholder: '', required: false },
                    { name: 'other', label: '其他资质', value: item.other, type: 'image', placeholder: '', required: false },
                    { name: 'wechat_payment_code', label: '微信收款码', value: item.wechat_payment_code, type: 'image', placeholder: '', required: false },
                    { name: 'zfb_payment_code', label: '支付宝收款码', value: item.zfb_payment_code, type: 'image', placeholder: '', required: false },
                ]
            })
        },
        modal_personnel_cooperating_edit_submit(data) {
            console.log('发送的数据', data)
            this.post('personnel_cooperating_edit', { ...data }).then(({ data }) => {
                this.$toast({ title: '修改成功' })
                this.personnel_cooperating_get()
            })
        },
        //删除服务人员
        personnel_cooperating_delete(item) {
            this.$refs.modal_personnel_cooperating_delete.show({
                type: 'query',
                title: '修改服务人员',
                content: '是否确认删除？',
                data: [
                    { name: 'id', label: '编码', value: item.id, required: true, disabled: true }
                ]
            })
        },
        personnel_cooperating_delete_submit(item) {
            console.log('删除提交的数据', item)
            this.post('personnel_cooperating_delete', { id: item.id }).then(({ data }) => {
                this.$toast({ title: '删除成功' })
                this.personnel_cooperating_get()
            })
        },
        /**查看人员身份信息 */
        modal_viewPrsonnelDetail(item) {
            this.$refs.modal_viewPrsonnelDetail.show({
                type: 'form',
                title: '身份信息',
                data: [
                    { name: 'name', label: '姓名', value: item.name, placeholder: '', disabled: true },
                    { name: 'phone_number', label: '电话号码', value: item.phone_number, placeholder: '', maxlength: 11, disabled: true },
                    { name: 'id_card', label: '身份证号', value: item.id_card, placeholder: '', maxlength: 18, disabled: true },
                    { name: 'id_card_image_front', label: '身份证照片正面', value: item.id_card_image_front, type: 'image', placeholder: '', required: false, disabled: true },
                    { name: 'id_card_image_back', label: '身份证照片反面', value: item.id_card_image_back, type: 'image', placeholder: '', required: false, disabled: true }
                ]
            })
        },


        /************************ 信息收录 *******************************/
        //获取人员信息列表 
        personnel_info_get() {
            this.personnel_info_list = [];
            this.showLoading = true;
            let bread_crumb_selected = this.bread_crumb_selected;
            this.post('personnel_info_get', {}).then(({ list }) => {
                list.forEach(element => {
                    element.business_city = JSON.parse(element.business_city)
                    element.createTimeTxt = utils.formatTime(element.createTime, 'year-month-day hour:minute')
                });
                setTimeout(() => {
                    if (bread_crumb_selected !== this.bread_crumb_selected) return false;
                    this.personnel_info_list = list
                    this.showLoading = false
                }, 100);
            })
        },
        /**添加人员信息*/
        personnel_info_create(e) {
            this.$refs.modal_personnel_info_create.show({
                type: 'form',
                title: '创建匠人合作申请单',
                data: [
                    { name: 'business_city', label: '合作城市', type: 'picker', value: null, placeholder: '', required: true, disabled: false },
                    { name: 'name', label: '姓名', value: null, placeholder: '', required: true },
                    { name: 'phone_number', label: '电话号码', value: null, placeholder: '', required: true, maxlength: 11 },
                    { name: 'id_card', label: '身份证号', value: null, placeholder: '', required: false, maxlength: 18 },
                    { name: 'id_card_image_front', label: '身份证照片正面', value: null, type: 'image', placeholder: '', required: false },
                    { name: 'id_card_image_back', label: '身份证照片反面', value: null, type: 'image', placeholder: '', required: false },
                    { name: 'wechat_qr', label: '个人微信二维码', value: null, type: 'image', placeholder: '', required: false },
                    { name: 'wechat_payment_code', label: '微信收款码', value: null, type: 'image', placeholder: '', required: false },
                    { name: 'zfb_payment_code', label: '支付宝收款码', value: null, type: 'image', placeholder: '', required: false },
                    { name: 'other', label: '其他资质', value: null, type: 'image', placeholder: '', required: false }
                ]
            })
        },
        personnel_info_create_submit(data) {
            console.log('发送的数据', data)
            this.post('personnel_info_create', { ...data }).then(({ data }) => {
                this.$toast({ title: '新建完成' })
                this.personnel_info_get()
            })
        },
        //删除人员信息
        personnel_info_delete(item) {
            this.$refs.modal_personnel_info_delete.show({
                type: 'query',
                title: '修改服务人员',
                content: '是否确认删除？',
                data: [
                    { name: 'id', label: '编码', value: item.id, required: true, disabled: true }
                ]
            })
        },
        personnel_info_delete_submit(item) {
            console.log('删除提交的数据', item)
            this.post('personnel_info_delete', { id: item.id }).then(({ data }) => {
                this.$toast({ title: '删除成功' })
                this.personnel_info_get()
            })
        },
        personnel_info_remarks(item) {
            this.$refs.modal_personnel_info_remarks.show({
                type: 'form',
                title: '备注',
                data: [
                    { name: 'id', label: '编码', value: item.id, required: true, disabled: true, hide: true },
                    { name: 'remarks', label: '备注', value: item.remarks, placeholder: '', required: true, type: 'textarea', hideLabel: true }
                ]
            })
        },
        personnel_info_remarks_submit(item) {
            console.log('xxxxxsss', item)
            let id = item.id;
            let remarks = item.remarks;
            this.post('personnel_info_setRemarks', { id, remarks }).then(res => {
                this.$toast({ title: '操作成功' })
                this.tabBreadCrumb(this.bread_crumb_selected)
            })
        },
        /************************ 申请记录 *******************************/
        /**获取申请记录*/
        personnel_application_get() {
            this.personnel_application_list = [];
            this.showLoading = true;
            let bread_crumb_selected = this.bread_crumb_selected;
            this.post('personnel_application_get', {}).then(({ list }) => {
                list.forEach(element => {
                    element.business_city = JSON.parse(element.business_city)
                    element.createTimeTxt = utils.formatTime(element.createTime, 'year-month-day hour:minute')
                });
                setTimeout(() => {
                    if (bread_crumb_selected !== this.bread_crumb_selected) return false;
                    this.personnel_application_list = list
                    this.showLoading = false
                }, 100);
            })
        },
        personnel_application_delete(item) {
            console.log(item)
            this.$refs.modal_personnel_application_delete.show({
                type: 'query',
                title: '删除记录',
                content: '是否确认删除？',
                data: [
                    { name: 'id', label: '编码', value: item.id, required: true, disabled: true }
                ]
            })
        },
        personnel_application_delete_submit(item) {
            console.log('删除提交的数据', item)
            this.post('personnel_application_delete', { id: item.id }).then(({ data }) => {
                this.$toast({ title: '删除成功' })
                this.personnel_application_get()
            })
        },
        citySelectChange(item) {
            this.search()
        },
        search() {
            this.$refs.personnel_cooperating.search({
                value: this.searchValue,
                fields: ['name', 'phone_number'],
                force: {
                    fields: 'business_city',
                    value: this.selected_cities
                }
            })
        },
        searchInput(e){
            this.search()
            // if(e.target.value.length===0){
            //     console.log('ssadasd')
            //     this.$refs.personnel_cooperating.reset()
            // }
        },




        previewImages(e) {
            this.showPreviewImages = true
            this.preview_images = [e]
        },
      
    }
}
</script>
<style scoped >
.wrapper {
    color: #333;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #dddddd;
}

.header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dddddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 15px;
    font-size: 12.5px;
    font-family: "Microsoft YaHei", SimSun;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dddddd;
}

.bread_crumb_item {
    cursor: pointer;
    border-bottom: 2px solid #fff;
    padding: 10px 15px 8px 15px;
    color: #206bc4;
    font-size: 14px;
    margin-right: 4px;

}

.bread_crumb_item_selected {
    cursor: pointer;
    border-bottom: 2px solid #206bc4;
    padding: 10px 15px 8px 15px;
    border-radius: 5px 5px 0 0;
    color: #fff;
    background: #206bc4;
    font-size: 14px;
    margin-right: 4px;

}

.bread_crumb_item_selected:hover {
    background: #206bc4;
}

.bread_crumb_item:hover {
    background: #eeeeee;
}

.body {}

.tab {
    background-color: white;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    user-select: none;

}

.tab-select {
    color: white;
    background-color: #1f68be;
}

.tab:hover {
    background: #e6e6e6;
    color: black;
}

.tab:active {
    background: #e6e6e6;
    color: black;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.tab-border-left {
    border-left: 1px solid #cccccc;
}

.mybtn {
    background-color: #2c72b0;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    user-select: none;
    border-radius: 3px;
}

.mybtn:hover {
    background-color: #286090;
    color: #ffffff;
}

.mybtn:active {
    background-color: #1d5280;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}


.btn2 {
    background-color: #e8e8e8;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    border: 1px solid #555555;
    color: #000000;
    border-radius: 3px;
    user-select: none;
}

.btn2:hover {
    background-color: #cccccc;
}

.btn2:active {
    background-color: #aaaaaa;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}



.btn3 {
    background-color: #f0ad4e;
    padding: 8px 17px;
    font-size: 14px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    border-radius: 3px;
}

.btn3:hover {
    background-color: #e09b3c;
    color: #ffffff;
}

input {
    outline: none;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    border: 1px solid #cccccc;
}

input:focus {
    border: 1px solid rgb(56, 115, 192) !important;
}

.float {
    /* float: left; */
    /* min-width: 25%; */
    height: 100%;
    display: flex;
    padding: 10px 0;
}
</style>
  