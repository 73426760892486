function formatTime(aaa, format) {
    let date = new Date(aaa*1000)
    let sDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds()
    }
    let result = format.replace('year', sDate.year).replace('month', sDate.month < 10 ? '0' + sDate.month : sDate.month).replace('day', sDate.day < 10 ? '0' + sDate.day : sDate.day).replace('hour', sDate.hour < 10 ? '0' + sDate.hour : sDate.hour).replace('minute', sDate.minute < 10 ? '0' + sDate.minute : sDate.minute).replace('second', sDate.second < 10 ? '0' + sDate.second : sDate.second);
    return result
}


function isPhone (phone) {
  var myreg = /^[1][1-9][0-9]{9}$/;
  if (!myreg.test(phone)) {
    return false;
  } else {
    return true;
  }
}

module.exports = {
  formatTime,
  isPhone
}