<template>
    <div v-if="value" @wheel="onWheel" class="preview"
        style="width: 100vw;height: 100vh;background-color: #00000088;position: fixed;left: 0;top: 0;z-index: 8888;display: flex;align-items: center;justify-content: center;">
        <img :src="src[0]" alt="preview" style="height: 80vh;object-fit: contain;cursor: grab;transition: transform 0.3s ease-out;" 
            :style="{ transform: 'scale(' + scale + ')', minWidth: minScale * 100 + '%', minHeight: minScale * 100 + '%' }">
        
        <div @click="hide" style="" class="closeBtn">
            <img style="position: fixed;right: 20px;top: 25px;height: 23px;width: 23px;cursor: pointer;z-index: 222;opacity: 0.4;" src="../../assets/close.png"/>
        </div>

    </div>
</template>

<script>
export default {
    name: 'PreviewImages',
    props: {
        src: {
            type: String
        },
        value: {
            type: Boolean,
            default: 0
        }
    },
    mixins: [],
    data() {
        return {
            scale: 1, // 初始缩放比例为1
            minScale: 0.2 // 最小缩放比例为0.2
        };
    },
    mounted() {
        // 组件挂载完成回调，处理逻辑
    },
    methods: {
        hide() {
            this.$emit('input', false)
        },
        onWheel(event) {
            const delta = event.deltaY; // 获取滑轮滚动的距离(delta)
            const scaleFactor = delta > 0 ? 0.9 : 1.1; // 计算缩放比例(scale factor)
            let newScale = this.scale * scaleFactor; // 更新缩放比例(scale factor)
            if (newScale >= this.minScale) {
                this.scale = newScale;
            }
            event.preventDefault();
        }
    }
}
</script>
<style scoped>
.preview {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.closeBtn {
    width: 220px;
    height: 220px;
    position: fixed;
    right: -120px;
    top: -120px;
    background-color: #000000aa;
    cursor: pointer;
    border-radius: 50%;
}

.closeBtn:hover {
    background-color: #000000ca;
}</style>