<template>
    <div class="home" style="width: 100vw;height: 100vh;">
        <div
            style="width: 422px;height: 350px;background-color: white;border-radius: 7px;box-sizing: border-box;padding: 45px;position: relative;">
            <div>
                <div style="font-size: 24px;">登录</div>
                <b-form-group id="input-group-1" label-for="input-1" class="mb-4">
                    <input v-model="formData.admin_account" id="admin_account"
                        style="margin-top: 25px;width: 300px;padding:5px 10px;" placeholder="账户名" />
                </b-form-group>
                <b-form-group id="input-group-1" label-for="input-1" class="mb-4">
                    <input v-model="formData.admin_password" id="admin_password"
                        style="margin-bottom: 15px;width: 300px;padding:5px 10px;" placeholder="密码" />
                </b-form-group>
                <b-button @click="onSubmit" block variant="info" style="height: 40px;">登录</b-button>
            </div>
            <div v-show="visable"
                style="position: absolute;left: 0;top:0;height: 100%;width:100%;z-index: 2;display: flex;align-items: center;justify-content: center;background-color: white;border-radius: 7px;box-sizing: border-box;padding-bottom: 30px;">
                <span style="margin-left: 14px;color: #333333;font-size: 20px;">登入中...</span>
            </div>
        </div>


    </div>
</template>
<script>
export default {
    name: 'login',
    data() {
        return {
            visable: false,
            formData: { admin_account: '', admin_password: '' }
        };
    },
    mounted() {

    },
    methods: {
        onSubmit(e) {
            console.log(this.formData)
            // this.visable = true
            this.post('login', this.formData).then(async res => {
                await this.$store.set('token', res.admin_token)
                await this.$store.set('admin_account', this.formData.admin_account)
                this.$router.push('/')
            }).catch(err => {
                // this.visable = false
            })
        }
    }
}
</script>
<style>
.home {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f9;
    font-family: PingFangSC-Medium, sans-serif;
    --td-brand-color: #333333;
    --td-brand-color-hover: #333333;
    --td-brand-color-focus: #555555;
    --td-brand-color-active: #444444;
    --td-border-level-2-color: #555555;
    --td-component-border: #555555;
    /* --td-brand-color-disabled:#333333;
    --td-brand-color-light: #333333; */
}

.submitBtn {
    margin-top: 30px;
    background-color: #222222;
    color: white;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    box-sizing: border-box;

}

.submitBtn:hover {
    background-color: #323232;
}
</style>