






<!-- wsadasd -->
<template>
    <div v-show="showMap" class="home-container"
        style="height:100%;width:100%;overflow: hidden;position: relative;background-color: #fff;">
        <div v-if="showTitleBar"
            style="height: 40px;background: #2f62a1;width: 100%;display: flex;align-items: center;justify-content: space-between;color: white;">
            <span style="padding-left: 10px;">地图</span>
            <div class="close" @click="hide()">×</div>
        </div>
        <div id="container" style="height:100%;width:100%;position: relative;">

        </div>
        <div v-show="showSearchInput"
            style="position: absolute;z-index: 5555;background-color: #ffffff;padding: 10px 8px 10px 8px;left: 0;top: 40px;">
            <input v-model="keyword" @keyup="searchValueChange" autocomplete="off" id="keyword" placeholder="请输入搜索位置"
                style="padding: 3px 7px 3px 5px;width: 230px;border: 1px solid #ced4da;margin-bottom: 5px;  font-size: 13px;" />
            <div @click="selcetSearchValue(item)" v-for="(item, index) in searchTips" :key="index" class="searchTips">
                <div>{{ item.name }}
                    <span style="color: #999999;">{{ item.pname + item.cityname + item.adname }}</span>
                </div>
            </div>
            <div v-if="searchTips.length==0" class="searchTips">
                <div>
                    <span style="color: #999999;">暂无数据</span>
                </div>
            </div>
        </div>
        <slot name="content"></slot>
    </div>
</template>
  
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
    name: "Amap",
    props: {
        key: {
            type: String
        },
        location: {
            type: Array
        },
        showTitleBar: {
            type: Boolean,
            default: true
        },
        showSearchInput: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            map: null,
            showMap: false,
            keyword: null,
            searchTips: [],
            tempMaker: [],
            showSearchInput: false,
            AMap: null
        };
    },
    mounted() {
        console.log('Map mounted')
        this.initMap();
    },
    methods: {
        initMap(params) {
            return new Promise((resolve, reject) => {
                // let location = params ? params.location : [116.397767, 39.911039];
                // let selection = params ? params.selection : false;
                // let zoom = params ? params.zoom : 15;
                // let markers = params ? params.markers : null;
                window._AMapSecurityConfig = {
                    securityJsCode: '86f1d8939e1344109626dbf83699cf86',
                }
                console.log('地图显示')
                let that = this;
                AMapLoader.load({
                    key: "9a43272aba85788bdd0797832815afd3", // 申请好的Web端开发者Key，首次调用 load 时必填
                    version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    plugins: ["AMap.ToolBar", "AMap.Scale", "AMap.Geocoder", 'AMap.PlaceSearch', 'AMap.AutoComplete', 'AMap.MarkerClusterer', 'AMap.Marker', 'AMap.InfoWindow'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                }).then(AMap => {
                    that.map = new AMap.Map("container", {
                        resizeEnable: true,
                        center: [116.397767, 39.911039],
                        zoom: 15
                    });
                    that.AMap = AMap;
                    that.AutoComplete = new AMap.AutoComplete({ city: "全国" });
                    that.placeSearch = new AMap.PlaceSearch({ map: that.map });
                    that.map.on('complete', () => { })
                    resolve()

                }).catch(e => {
                    console.log(e);
                });
            })

        },
        hide() {
            this.showMap = false
            this.map.clearMap();
            this.initMap();
        },
        show() {
            this.showMap = true
        },
        onClick() {
            console.log('showSearchInput', this.showSearchInput)
            this.showSearchInput = true
            this.map.on("click", this.clickMapHandler);

        },
        setCenter(location) {
            console.log('setCenter', location)
            this.map.setCenter(location)
        },
        setZoom(zoom) {
            this.map.setZoom(zoom)
        },
        MarkerCluster(markers) {
            console.log('markers:创数', markers)
            let that = this
            const points = [];
            markers.forEach(element => {
                points.push({
                    lnglat: element.location,
                    extData: {
                        position: element.location,
                        content: element.data
                    },
                });
            })

            this.markerCluster = new AMap.MarkerCluster(this.map, points, {
                styles: [{
                    url: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
                    size: new AMap.Size(51, 70),
                    offset: new AMap.Pixel(-25, -35)
                }, {
                    url: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
                    size: new AMap.Size(51, 70),
                    offset: new AMap.Pixel(-25, -35)

                }, {
                    url: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
                    size: new AMap.Size(51, 70),
                    offset: new AMap.Pixel(-25, -35)

                }, {
                    url: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
                    size: new AMap.Size(51, 70),
                    offset: new AMap.Pixel(-25, -35)

                }, {
                    url: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
                    size: new AMap.Size(51, 70),
                    offset: new AMap.Pixel(-25, -35)

                }],
                renderMarker: function (context) {
                    let { storeName, province, city, tel, detail } = context.data[0].extData.content;
                    console.log(storeName, province, city, tel, detail, context.data[0].extData.content)
                    let position = context.data[0].extData.position
                    var info = [];
                    info.push("<div style=\"margin-bottom:80px;\" class='input-card content-window-card'><div><img style=\"float:left;width:67px;height:67px;\" src=\" https://cloud-1258469261.cos.ap-nanjing.myqcloud.com/1682749420680 \"/></div> ");
                    info.push("<div style=\"padding:7px 0px 0px 0px;\"><h4>" + storeName + "</h4>");
                    info.push("<p class='input-item'>电话 :" + tel + " </p>");
                    info.push("<p class='input-item'>地址 :" + province + city + detail + "</p></div></div>");
                    let infoWindow = new AMap.InfoWindow({
                        content: info.join(""),  //使用默认信息窗体框样式，显示信息内容
                        offset: new AMap.Pixel(0, -30) // 横向偏移0像素，纵向偏移-30像素

                    });
                    let mouseover = (ev) => {
                        console.log('鼠标移入', ev)
                        infoWindow.open(that.map, position);
                    }
                    // let mouseout = (ev) => {
                    //     console.log('鼠标移出', ev)
                    // }
                    context.marker.off('mouseover', mouseover);
                    // context.marker.off('mouseout', mouseout);
                    context.marker.on('mouseover', mouseover);
                    // context.marker.on('mouseout', mouseout);
                }
            });
        },
        setMarkers(markers) {
            console.log('设置的数据', markers)
            if (this.marker) {
                this.marker.setMap(null);
                this.marker = null;
            }
            let AMap = this.AMap
            markers.forEach(element => {
                this.marker = new AMap.Marker({
                    icon: new AMap.Icon({
                        image: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
                        imageSize: new AMap.Size(45, 60),
                        size: new AMap.Size(45, 60),
                        imageOffset: new AMap.Pixel(0, 0),
                    }),
                    position: element.location,
                    extData: element.data || {},
                    offset: new AMap.Pixel(-13, -30),
                    // label: element.content ? {
                    //     content: element.content,
                    //     offset: new AMap.Pixel(-50, -40), //向上偏移20像素
                    //     backgroundColor: "#000000", //背景色为黑色
                    //     color: "#fff", //字体色为白色
                    //     textAlign: 'left',
                    // } : null
                });
                this.marker.setMap(this.map);
                let that = this;
                // this.marker.on('click', function (e) {//mouseover
                //     // 设置标记点的样式和内容等
                //     let data = e.target.getExtData()
                //     that.$emit('onMarkers', {
                //         data
                //     });
                // });

            });
        },
        clickMapHandler(event) {
            console.log(event)
            if (this.marker) {
                this.marker.setMap(null);
                this.marker = null;
            }

            this.$emit('click', {
                lng: event.lnglat.lng,
                lat: event.lnglat.lat
            });
            this.marker = new AMap.Marker({
                icon: new AMap.Icon({
                    image: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
                    imageSize: new AMap.Size(35, 45),
                    size: new AMap.Size(35, 45),
                    imageOffset: new AMap.Pixel(0, 0),
                }),
                position: [event.lnglat.lng, event.lnglat.lat],
                offset: new AMap.Pixel(-13, -30),
                label: {
                    content: "当前标记",
                    offset: new AMap.Pixel(-50, -40), //向上偏移20像素
                    backgroundColor: "#000000", //背景色为黑色
                    color: "#fff" //字体色为白色
                }
            });
            this.marker.setMap(this.map);
        },
        // selectPoint() {
        //     this.hide()
        //     this.$emit('click', [event.lnglat.lng, event.lnglat.lat])
        // },
        searchValueChange(e) {
            let value = e.target.value;
            if (value && value.length > 0) {
                this.placeSearch.search(value, (status, result) => {
                    // 查询成功时，result即对应匹配的POI信息
                    console.log('searchValueChange', result.poiList)
                    if (result.poiList) {
                        this.searchTips = result.poiList.pois || [];
                    }
                });
            } else {
                this.searchTips = []

            }
        },
        selcetSearchValue(item) {
            console.log(item)
            let { lng, lat } = item.location
            // this.map.setCenter([lng, lat]);
            // this.map.setZoom(13)
            // console.log(item,this.placeSearch)
            this.placeSearch.setCity(item.adcode);
            this.placeSearch.search(item.name);
        }
    }
};
</script>
<style scoped>
.close {
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    cursor: pointer;
    color: white;
}

.close:hover {
    background: #214c81;
    color: white;
}

.searchTips {
    background-color: #ffffff;
    margin-top: 1px;
    padding: 7px 5px 5px 5px;
    font-size: 13px;
    text-align: left;
    cursor: pointer;
    max-width: 230px;
    user-select: none;
}

.searchTips:hover {
    background-color: #cae1ff;
}

input:focus {
    outline: none;
}

.amap-sug-result {
    z-index: 8888888;
}
</style>
<style>
.amap-marker-label {
    background-color: #000 !important;
    color: white;
    border-radius: 4px;
    padding: 7px 10px;
    border: none;
}
</style>