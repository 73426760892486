<template>
    <div style="display: flex;align-items: center;">
        <b-form-select v-model="selectedProvince" placeholder="Select an option" :options="provinces" @change="updateCities"
            text-field="name" value-field="code" style="margin-right: 5px;">
            <template #first>
                <b-form-select-option :value="null" disabled>-- 选择省份 --</b-form-select-option>
            </template>
        </b-form-select>
        <b-form-select style="margin-right: 5px;" v-model="selectedCity" :options="cities" text-field="name" value-field="code">
            <template #first>
                <b-form-select-option :value="null" disabled>-- 选择城市 --</b-form-select-option>
            </template>
        </b-form-select>
        <b-form-select style="margin-right: 5px;" v-model="selectedArea" :options="areas" text-field="name" value-field="code">
            <template #first>
                <b-form-select-option :value="null" disabled>-- 选择区县 --</b-form-select-option>
            </template>
        </b-form-select>
    </div>
</template>
    
<script>
const { provinces, cities, areas } = require('./data.json')
export default {
    props: {
        value: {
            type: Array,
            default: []
        }
    },
    mounted() {
        console.log('mounted address', this.value)
        this.setValue(this.value)
        console.log('mounted address2', this.selectedProvince_name,  this.selectedProvince_name, this.selectedArea_name)
    },
    data() {
        return {
            selectedProvince: null,
            selectedCity: null,
            selectedProvince_name: '',
            selectedCity_name: '',
            selectedArea: null,
            selectedArea_name: '',
            provinces: provinces,
            all_cities: cities,
            all_areas: areas,
            cities: [],
            areas: [],
        };
    },
    methods: {
        updateCities() {
            this.selectedCity = '';
        },
        setValue(value) {
          if (value.length > 1) {
            this.selectedProvince_name = value[0]
            this.selectedCity_name = value[1]
            this.selectedArea_name = value[2]

            this.provinces.forEach(item => {
              if (item.name === this.selectedProvince_name) {
                this.selectedProvince = item.code
              }
            })
            if (this.selectedProvince) {
              let cities = [];
              for (let item of this.all_cities) {
                if (item.provinceCode == this.selectedProvince) {
                  cities.push(item)
                }
                if (item.name === this.selectedCity_name) {
                  this.selectedCity = item.code
                }
              }
              this.cities = cities;
            }
            if (this.selectedCity) {
              let areas = [];
              for (let item of this.all_areas) {
                if (item.cityCode == this.selectedCity) {
                  areas.push(item)
                }
                if (item.name === this.selectedArea_name) {
                  this.selectedArea = item.code
                }
              }
              this.areas = areas;
            }
          }
        }
    },
    watch: {
        value(newValue, oldValue) {
          console.log('watch value', newValue, oldValue)
        },
        selectedProvince(code, name) {
            console.log("watch selectedProvince",code, name)
            this.selectedCity = '';
            this.selectedArea = '';
            let cities = [];
            for (let item of this.all_cities) {
                if (item.provinceCode == code) {
                    cities.push(item)
                }
                if (item.name === this.selectedCity_name) {
                    this.selectedCity = item.code
                  }
            }
            this.cities = cities;
        },
        selectedCity(code, name) {
          console.log("watch selectedCity",code, name)
            console.log("selectedCity", this.cities,this.selectedCity)
            this.selectedArea = ''
            let areas = [];
            for (let item of this.all_areas) {
                if (item.cityCode == code) {
                  areas.push(item)
                }
                if (item.name === this.selectedArea_name) {
                    this.selectedArea = item.code
                  }
            }
            this.areas = areas;
        },
        selectedArea() {
            console.log("selectedArea", this.selectedArea)
          
            this.provinces.forEach(item => {
                if (item.code === this.selectedProvince) {
                  this.selectedProvince_name = item.name
                }
            })

            this.cities.forEach(item => {
              if (item.code === this.selectedCity) {
                this.selectedCity_name = item.name
              }
            })

            this.areas.forEach(item => {
                if (item.code === this.selectedArea) {
                    this.selectedArea_name = item.name
                }
            })
            console.log("选择的地区:", this.selectedProvince_name, this.selectedCity_name, this.selectedArea_name)

            if (this.selectedProvince_name && this.selectedCity_name && this.selectedArea_name) {
                this.array = [this.selectedProvince_name, this.selectedCity_name, this.selectedArea_name]
            } else {
                this.array = []
            }
            this.$emit('input',this.array)

        }

    }
};
</script>