import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../pages/login/login.vue'
import index from '../pages/index/index.vue'
import map from '../pages/map/map.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/map',
    name: 'map',
    component: map,
    meta: {
    target: '_blank' // 在新窗口打开
    }
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('router:to', to)
  console.log('router:from', from)
  if (to.path === '/login') {
    next()
  } else if (!Vue.prototype.$store.get('token')) {
    next('/login')
  } else {
    next()
  }
})

export default router
