<template>
  <div style="padding-bottom: 20px;">
    <div class="breadCrumb" style="display: flex;">
      <div @click="tabBreadCrumb(index)" v-for="(item, index) in breadCrumb" :key="index"
        :class="index == bread_crumb_selected ? 'bread_crumb_item_selected' : 'bread_crumb_item'">{{ item.name }}</div>
    </div>


    <div class="wrapper">
      <!-- 店铺设置 -->
      <div v-if="bread_crumb_selected == 0" class="body" style="font-size: 14px;">
        <!-- 测试 -->

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <i style="color:rgb(219, 94, 90);margin-right: 10px;">*</i>
            <div>店铺名称</div>
          </div>
          <input v-model="config.app_title" style="height: 34px;width: 40%;" type="text">
        </div>


        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>店铺分类</div>
          </div>
          <input v-model="config.businesses_category"  style="height: 34px;width: 40%;" type="text">
          多个，使用逗号分割
        </div>


        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>客服电话</div>
          </div>
          <input v-model="config.customer_phone_num" maxlength="11" style="height: 34px;width: 40%;" type="text">
        </div>

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>预约电话</div>
          </div>
          <input v-model="config.reserve_phone" style="height: 34px;width: 40%;" type="text">
          多个，使用逗号分割
        </div>

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>小程序预约按钮名称</div>
          </div>
          <input v-model="config.reserve_btn" style="height: 34px;width: 40%;" type="text">
        </div>


        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>微信客服二维码</div>
          </div>
          <Upload v-model="config.customer_qr"></Upload>
        </div>

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>支付宝客服二维码</div>
          </div>
          <Upload v-model="config.zfb_customer_qr"></Upload>
        </div>

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>支付宝广告位跳转</div>
          </div>
          <div style="display: flex;align-items: center;width: 40%;">
            <b-form-select change="ad_type_change" style="height: 34px;width: 30%;font-size: 13px;" v-model="zfb_ad_type"
              :options="zfb_options"></b-form-select>
            <input v-model="config.zfb_ad_link"
              :placeholder="zfb_ad_type == 0 ? '请输入支付宝小程序appid' : zfb_ad_type == 1 ? '请输入网页地址 例如www.baidu.com' : '小程序页面路径 例:/pages/mine/help/detail?id=1'"
              style="height: 34px;width: 70%;" type="text">
          </div>
        </div>

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>微信广告位跳转</div>
          </div>
          <div style="display: flex;align-items: center;width: 40%;">
            <b-form-select change="ad_type_change" style="height: 34px;width: 30%;font-size: 13px;" v-model="ad_type"
              :options="options"></b-form-select>
            <input v-model="config.ad_link"
              :placeholder="ad_type == 0 ? '请输入支付宝小程序appid' : ad_type == 1 ? '请输入网页地址 例如www.baidu.com' : '小程序页面路径 例:/pages/mine/help/detail?id=1'"
              style="height: 34px;width: 70%;" type="text">
          </div>
        </div>

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">

            <div>广告位标题</div>
          </div>
          <input v-model="config.ad_title" style="height: 34px;width: 40%;" type="text">
        </div>

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">

            <div>广告位按钮文字</div>
          </div>
          <input v-model="config.ad_btn_content" style="height: 34px;width: 40%;" type="text">
        </div>

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>订单处理人手机号</div>
          </div>
          <input v-model="config.responsible_person_phone_num" maxlength="11" style="height: 34px;width: 40%;"
            type="text">
        </div>

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>开启订阅消息</div>
          </div>
          <b-form-checkbox style="height: 30px;" size="lg" v-model="config.subscribeMessage" name="" switch>

          </b-form-checkbox>
        </div>

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>当前位置气泡内容</div>
          </div>
          <input v-model="config.bubble" maxlength="11" style="height: 34px;width: 40%;" type="text">
        </div>

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>平台保障图标</div>
          </div>
          <Upload v-model="config.platform_protection_icon" :width="80" :height="80"></Upload>
        </div>

        <div style="display: flex;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;justify-content: flex-end;font-weight: 700;padding-right: 25px;margin-top: 4px;">
            <div>平台保障</div>
          </div>
          <textarea v-model="config.platform_protection" style="height: 234px;width: 40%;" name="" id="" cols="30"
            rows="10"></textarea>
        </div>

        <div style="display: flex;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;justify-content: flex-end;font-weight: 700;padding-right: 25px;margin-top: 4px;">
            <div>订购须知</div>
          </div>
          <textarea v-model="config.ordering_instructions" style="height: 234px;width: 40%;" name="" id="" cols="30"
            rows="10"></textarea>
        </div>



        <div style="display: flex;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;justify-content: flex-end;font-weight: 700;padding-right: 25px;margin-top: 4px;">
            <div>用户服务协议</div>
          </div>
          <textarea v-model="config.user_service_agreement" style="height: 234px;width: 40%;" name="" id="" cols="30"
            rows="10"></textarea>
        </div>

        <div style="display: flex;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;justify-content: flex-end;font-weight: 700;padding-right: 25px;margin-top: 4px;">
            <div>隐私保护政策</div>
          </div>
          <textarea v-model="config.privacy_protection_policy" style="height: 234px;width: 40%;" name="" id="" cols="30"
            rows="10"></textarea>
        </div>
        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>服务号AppId</div>
          </div>
          <input v-model="config.wxgzh_appid" style="height: 34px;width: 40%;" type="text">
        </div>
        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>服务号Secret</div>
          </div>
          <input v-model="config.wxgzh_secret" style="height: 34px;width: 40%;" type="text">
        </div>
        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>腾讯云存储secretId</div>
          </div>
          <input v-model="config.secretId" style="height: 34px;width: 40%;" type="text">
        </div>
        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>腾讯云存储secretKey</div>
          </div>
          <input v-model="config.secretKey" style="height: 34px;width: 40%;" type="text">
        </div>
        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>腾讯云存储Bucket</div>
          </div>
          <input v-model="config.Bucket" style="height: 34px;width: 40%;" type="text">
        </div>
        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>腾讯云存储Region</div>
          </div>
          <input v-model="config.Region" style="height: 34px;width: 40%;" type="text">
        </div>

        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>服务号模板ID-下单</div>
          </div>
          <input v-model="config.template_id_placeOrder" style="height: 34px;width: 40%;" type="text">
        </div>
        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>服务号模板ID-商家入驻</div>
          </div>
          <input v-model="config.template_id_businessApplication" style="height: 34px;width: 40%;" type="text">
        </div>
        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>服务号模板ID-工匠入驻</div>
          </div>
          <input v-model="config.template_id_personnelApplication" style="height: 34px;width: 40%;" type="text">
        </div>
        <div style="display: flex;align-items: center;margin-top: 17px;">
          <div
            style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
            <div>服务号模板ID-用户评价</div>
          </div>
          <input v-model="config.template_id_evaluation" style="height: 34px;width: 40%;" type="text">
        </div>




        
        <div
          style="background-color: #f5f5f5;width: 100%;display: flex;align-items: center;justify-content: center;margin-top: 14px;padding: 14px 0;">
          <b-button class="mybtn" @click="submit" style="width: 30%;padding: 8px;font-size: 14px;">保存设置</b-button>
        </div>
      </div>

      <!-- 帮助中心 -->

      <div v-if="bread_crumb_selected == 1" class="body" style="font-size: 14px;">
        <div>
          <div v-for="(item, index) in help" :key="index" style="margin-bottom: 14px;">
            <div style="display: flex;align-items: center;margin-top: 17px;">
              <div
                style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
                <div>问题{{ index + 1 }}</div>
              </div>
              <input v-model="item.question" style="height: 34px;width: 40%;" type="text">
            </div>
            <div style="display: flex;margin-top: 17px;">
              <div
                style="width:16.6%;display: flex;justify-content: flex-end;font-weight: 700;padding-right: 25px;margin-top: 4px;">
                <div>回答</div>
              </div>
              <div style="position: relative;width: 40%;">
                <textarea v-model="item.answer" style="height: 234px;width: 100%;" name="" id=""></textarea>
                <div
                  style="position: absolute;right: -26px;bottom: 10px;font-size: 13px;display: flex;flex-direction: column;align-items: center;">
                  <img v-if="index !== 0" @click="moveUpHelp(index)" src="../assets/up.png"
                    style="width: 20px;height: 20px;cursor: pointer;" />
                  <img @click="deleteHelp(index)" src="../assets/delete.png"
                    style="width: 18px;height: 18px;cursor: pointer;margin-top: 11px;" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div @click="addHelp" class="add">+</div>
        <div
          style="background-color: #f5f5f5;width: 100%;display: flex;align-items: center;justify-content: center;padding: 14px 0;border-top: 1px solid #cccccc;">
          <b-button class="mybtn" @click="submitHelp" style="width: 30%;padding: 8px;font-size: 14px;">保存设置</b-button>
        </div>
      </div>

      <!-- 主题颜色 -->
      <div v-if="bread_crumb_selected == 2" class="body" style="font-size: 14px;">
        <div style="margin-bottom: 14px;">
          <div style="display: flex;align-items: center;margin-top: 17px;">
            <div
              style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
              <div>主题颜色</div>
            </div>
            <input type="color" style="width: 50px;" v-model="config.themeColor" class="form-control form-control-color">
          </div>
          <div style="display: flex;align-items: center;margin-top: 17px;">
            <div
              style="width:16.6%;display: flex;align-items: center;justify-content: flex-end;font-weight: 700;padding-right: 25px;">
              <div>按钮文字颜色</div>
            </div>
            <input type="color" style="width: 50px;" v-model="config.btnColor" class="form-control form-control-color">
          </div>
        </div>

        <div
          style="background-color: #f5f5f5;width: 100%;display: flex;align-items: center;justify-content: center;padding: 14px 0;border-top: 1px solid #cccccc;">
          <b-button class="mybtn" @click="submittheme" style="width: 30%;font-size: 14px;">保存设置</b-button>
        </div>
      </div>

      <!-- 合作方公示 -->

      <div v-if="bread_crumb_selected == 3" class="body" style="font-size: 14px;">
        <div>
          <div v-for="(item, index) in partnership" :key="index" style="margin-bottom: 14px;">
            <div style="display: flex;margin-top: 17px;width: 80%;">
              <div
                style="width:16.6%;display: flex;justify-content: flex-end;font-weight: 700;padding-right: 25px;margin-top: 8px;">
                <div>合作方{{ index + 1 }}</div>
              </div>
              <Upload v-model="item.image"></Upload>
              <div
                style="height: 95px;font-size: 12px;line-height: 22px;display: flex;flex-direction: column;justify-content: space-between;position: relative;">
                <div style="display: flex;align-items: center;margin-left: 15px;">
                  <span style="width: 100px;text-align: right;">标题</span>
                  <input v-model="item.title" style="height: 22px;width: 250px;margin-left: 10px;" type="text">
                </div>
                <div style="display: flex;align-items: center;margin-left: 15px;">
                  <span style="width: 100px;text-align: right;">简介</span>
                  <input v-model="item.introduction" style="height: 22px;width: 250px;margin-left: 10px;" type="text">
                </div>
                <div style="display: flex;align-items: center;margin-left: 15px;">
                  <span style="width: 100px;text-align: right;">跳转小程序appid</span>
                  <input v-model="item.appid" style="height: 22px;width: 250px;margin-left: 10px;" type="text">
                </div>
                <div style="display: flex;align-items: center;margin-left: 15px;">
                  <span style="width: 100px;text-align: right;">跳转小程序路径</span>
                  <input v-model="item.path" style="height: 22px;width: 250px;margin-left: 10px;" type="text">
                </div>

                <div
                  style="position: absolute;right: -36px;bottom: 5px;font-size: 13px;display: flex;flex-direction: column;align-items: center;">
                  <img v-if="index !== 0" @click="partnership_move(index)" src="../assets/up.png"
                    style="width: 20px;height: 20px;cursor: pointer;" />
                  <img @click="partnership_delete(index)" src="../assets/delete.png"
                    style="width: 18px;height: 18px;cursor: pointer;margin-top: 11px;" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div @click="partnership_add" class="add">+</div>
        <div
          style="background-color: #f5f5f5;width: 100%;display: flex;align-items: center;justify-content: center;padding: 14px 0;border-top: 1px solid #cccccc;">
          <b-button class="mybtn" @click="partnership_save"
            style="width: 30%;padding: 8px;font-size: 14px;">保存设置</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const utils = require('../utils/utils')
// import COS from 'cos-js-sdk-v5';
// var cos = new COS({
//   SecretId: 'AKIDf9PQCj13qOgiX7RWMwGK9j38xXrvkKej', // 推荐使用环境变量获取；用户的 SecretId，建议使用子账号密钥，授权遵循最小权限指引，降低使用风险。子账号密钥获取可参考https://cloud.tencent.com/document/product/598/37140
//   SecretKey: 'rWx84kBNJzQbWCcniNnxsXw62i9LIzQc', // 推荐使用环境变量获取；用户的 SecretKey，建议使用子账号密钥，授权遵循最小权限指引，降低使用风险。子账号密钥获取可参考https://cloud.tencent.com/document/product/598/37140
// });
import { BModal } from 'bootstrap-vue'
import Upload from '../components/Upload/Upload'
export default {
  name: 'Setting',
  components: {
    'b-modal': BModal,
    'Upload': Upload
  },
  data() {
    return {
      breadCrumb: [
        { name: '店铺设置', methods: '' },
        { name: '帮助中心' },
        { name: '主题设置' },
        { name: '合作方公示' }
      ],
      bread_crumb_selected: 0,
      config: {},
      uploading: false,
      upload_process: 0,
      options: [
        { value: 0, text: '跳转小程序' },
        { value: 1, text: '跳转网页' },
        { value: 2, text: '跳转页面路径' }
      ],
      zfb_options: [
        { value: 0, text: '跳转小程序' },
        { value: 1, text: '跳转网页' },
        { value: 2, text: '跳转页面路径' }
      ],
      ad_type: 0,
      zfb_ad_type: 0,
      help: [],
      partnership: []
    };
  },
  mounted() {
    // 组件挂载完成回调，处理逻辑
    this.getConfig()
  },
  methods: {
    tabBreadCrumb(index) {
      console.log(index)
      this.bread_crumb_selected = index
      if (index == 0) {
        this.getConfig()
      } else if (index == 1) {
        this.getHelp()
      } else if (index == 3) {
        this.partnership_get()
      }
    },
    getConfig() {
      this.get('config_get').then(({ config }) => {
        console.log(config)
        this.config = config
        this.ad_type = config.ad_type
        this.zfb_ad_type = config.zfb_ad_type
        config.subscribeMessage = config.subscribeMessage === 1
      })
    },
    getHelp() {
      this.get('helpList_get').then(({ help }) => {
        console.log(help)
        this.help = help
      })
    },
    addHelp() {
      this.help.push({
        question: '',
        answer: ''
      })
    },
    /* 合作方公示 */
    partnership_get() {
      this.get('partnership_get').then(({ list }) => {
        this.partnership = []
        this.partnership = list
      })
    },
    partnership_add() {
      this.partnership.push({
        title: '',
        content: '',
        introduction: ''
      })
      console.log(this.partnership)
    },
    partnership_save() {
      console.log('发送的数组', this.partnership)
      this.post('partnership_save', { partnership: this.partnership }).then((res) => {
        this.$toast({ title: '保存成功' })
        this.partnership_get()
      })
    },
    partnership_delete(index) {
      this.partnership.splice(index, 1)
    },
    partnership_move(index) {
      if (index !== 0) {
        let temp = this.partnership[index]
        this.partnership.splice(index - 1, 0, temp)
        this.partnership.splice(index + 1, 1)
      }
      console.log('移动后的数组', this.partnership)
    },

    /* 帮助中心 */
    deleteHelp(index) {
      this.help.splice(index, 1)
    },
    moveUpHelp(index) {
      if (index !== 0) {
        let temp = this.help[index]
        this.help.splice(index - 1, 0, temp)
        this.help.splice(index + 1, 1)
      }

    },
    submit() {
      console.log('config:', this.config)
      this.config.ad_type = this.ad_type;
      this.config.zfb_ad_type = this.zfb_ad_type;

      let subscribeMessage = this.config.subscribeMessage === true ? 1 : 0;
      this.post('config_set', { config: this.config, subscribeMessage }).then((res) => {
        console.log('更新返回', res)
        // this.config = config
        this.$toast({ title: '保存成功' })
      })
    },
    submitHelp() {
      this.post('help_set', { help: this.help }).then((res) => {
        console.log('更新返回', res)
        // this.config = config
        this.$toast({ title: '保存成功' })
      })
    },
    submittheme() {
      this.post('theme_set', { config: this.config }).then((res) => {
        console.log('更新返回', res)
        this.$toast({ title: '保存成功' })
      })
    },
    fileChange(e, key) {
      let that = this;
      console.log('存储信息', this.$cos)
      var files = [];
      that.upload_process = 0
      console.log(key)
      for (let i = 0; i < e.target.files.length; i++) {
        files.push({
          Bucket: this.$cos.Bucket, /* 填写自己的bucket，必须字段 */
          Region: this.$cos.Region,     /* 存储桶所在地域，必须字段 */
          Key: (Date.now() + i),  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
          Body: e.target.files[i], /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
          Prefix: '/',
          onTaskReady: function (taskId) {
            /* taskId可通过队列操作来取消上传cos.cancelTask(taskId)、停止上传cos.pauseTask(taskId)、重新开始上传cos.restartTask(taskId) */
            // console.log(taskId);
          }
        })
      }
      console.log(files)
      if (files.length !== 0) {
        var filesLength = files.length;
        var success = 0;
        that.uploading = true;

        if (!this.$cos) return this.$toast({ title: '腾讯云cos存储未设置' });
        this.$cos.cos.uploadFiles({
          files: files,
          SliceSize: 1024 * 1024 * 10,    /* 设置大于10MB采用分块上传 */
          onFileFinish: function (err, data, options) {
            filesLength -= 1
            success += err ? 0 : 1
            if (filesLength == 0) {
              if (success == files.length) {
                that.upload_process++
                console.log(`上传成功 ${success}张`)
              } else {
                console.log(`上传成功 ${success}张 失败${files.length - success}张`)
              }
            }
            console.log(options.Key + '上传' + (err ? '失败' : '完成'), '剩余', filesLength);
          },
        }, function (err, data) {
          console.log(err || data);
          if (err) {
            console.error('存储桶配置错误！')
          } else {
            console.log('sss', key)
            let uploadedFiles = [];
            data.files.forEach((item, i) => {
              uploadedFiles.push(`https://${data.files[i].data.Location}`)
            })
            switch (key) {
              case 'customer_qr':
                that.config.customer_qr = uploadedFiles[0];
                setTimeout(() => {
                  that.uploading = false
                }, 1000);
                console.log(that.config.customer_qr, uploadedFiles[0])
                break
            }

          }
        });
      }

    },
    colorChange(e) {
      console.log(e)
    }
  }
}
</script>
<style scoped >
.wrapper {
  color: #333;
  background-color: #fff;
  border-radius: 0 5px 5px 5px;
  border: 1px solid #dddddd;
  width: 88%;
}

.header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #dddddd;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 10px 15px;
  font-size: 12.5px;
  font-family: "Microsoft YaHei", SimSun;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dddddd;
}

.bread_crumb_item {
  cursor: pointer;
  border-bottom: 2px solid #fff;
  padding: 10px 15px 8px 15px;
  color: #206bc4;
  font-size: 14px;
  margin-right: 4px;

}

.bread_crumb_item_selected {
  cursor: pointer;
  border-bottom: 2px solid #206bc4;
  padding: 10px 15px 8px 15px;
  border-radius: 5px 5px 0 0;
  color: #fff;
  background: #206bc4;
  font-size: 14px;
  margin-right: 4px;

}

.bread_crumb_item_selected:hover {
  background: #206bc4;
}

.bread_crumb_item:hover {
  background: #eeeeee;
}

.body {}

.tab {
  background-color: white;
  padding: 5px 10px;
  font-size: 11.5px;
  cursor: pointer;
  touch-action: manipulation;
  user-select: none;

}

.tab-select {
  color: white;
  background-color: #1f68be;
}

.tab:hover {
  background: #e6e6e6;
  color: black;
}

.tab:active {
  background: #e6e6e6;
  color: black;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.tab-border-left {
  border-left: 1px solid #cccccc;
}

.mybtn {
  background-color: #2c72b0;
  padding: 5px 10px;
  font-size: 11.5px;
  cursor: pointer;
  touch-action: manipulation;
  color: #ffffff;
  user-select: none;
  height: 40px;
}

.mybtn:hover {
  background-color: #286090;
  color: #ffffff;
}

.mybtn:active {
  background-color: #1d5280;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}


.btn2 {
  background-color: #e8e8e8;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  padding: 5px 10px;
  font-size: 11.5px;
  cursor: pointer;
  touch-action: manipulation;
  border: 1px solid #555555;
  color: #000000;
  border-radius: 3px;
  user-select: none;
}

.btn2:hover {
  background-color: #cccccc;
}

.btn2:active {
  background-color: #aaaaaa;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

input {
  outline: none;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  border: 1px solid #cccccc;
}

input:focus {
  border: 1px solid rgb(56, 115, 192) !important;
}

.add {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  width: 100%;
  cursor: pointer;
  background-color: #f5f5f5;
}

.add:hover {
  background-color: #eeeeee;
}
</style>
