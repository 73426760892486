<template>
    <div>
        <div class="wrapper">
            <div class="header">
                <div v-if="bread_crumb_selected == 0"
                    style="display: flex;margin-top: 2px;margin-right: 10px;align-items: center;justify-content: space-between;width: 100%;">
                    <div></div>
                    <div style="display: flex;align-items: center;">
                        <div style="display: flex;align-items: center;margin-top: 2px;margin-right: 5px;">
                            <Select @change="citySelectChange" v-model="selected_cities" :options="cities"></Select>
                        </div>
                        <div style="display: flex;margin-top: 2px;">
                            <input v-model="searchValue" type="text" @input="searchInput"
                                style="border-radius: 3px 0 0 3px;border-right: none;border: 1px solid #cccccc;width: 130px;"
                                placeholder="">
                            <div @click="search" class="mybtn" style="border-radius: 0 3px 3px 0;">搜索</div>
                        </div>
                    </div>
                </div>
                <div v-if="bread_crumb_selected == 1" style="display: flex;margin-top: 2px;margin-right: 10px;">
                    <div @click="personnel_info_create" class="mybtn" style="">+ 添加</div>
                </div>
            </div>
            <div class="body">
                <div v-if="bread_crumb_selected == 0" style="padding: 10px;">
                    <Table ref="userList" :fields="fields" :data="userList" :searchValue="searchValue" :loadAll="loadAll" @scroll="table_scrollEvent">
                        <template v-slot:avatar="cell">
                            <img :src="cell.value" style="width: 35px;height: 35px;" alt=""/>
                        </template>
                        <template v-slot:operate="cell">
                            <div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap">
                                <div @click="user_edit(cell.item)"
                                    style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;margin-left: 10px;">
                                    <img src="../assets/edit.png" style="width: 22px;height: 22px;display: inline-block;"
                                        alt="">
                                    <span style="font-size: 13px;">修改</span>
                                </div>
                                <div @click="user_delete(cell.item)"
                                    style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;margin-left: 10px;">
                                    <img src="../assets/delete2.png" style="width: 22px;height: 22px;display: inline-block;"
                                        alt="">
                                    <span style="font-size: 13px;">删除</span>
                                </div>
                            </div>
                        </template>
                    </Table>

                    <div v-if="userList.length == 0 && !showLoading"
                        style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
                        暂无
                    </div>
                </div>

            </div>

        </div>


        <!-- 模态弹窗 - 账号权限 -->
        <Modal ref="user_delete" @confirm="user_delete_submit"></Modal>
        <Modal ref="user_edit" @confirm="user_edit_submit"></Modal>
        <PreviewImages v-model="showPreviewImages" :src="preview_images"></PreviewImages>
    </div>
</template>
<script>
const utils = require('../utils/utils')
import PreviewImages from '../components/PreviewImages/PreviewImages'
import Modal from '../components/Modal/Modal'
import Table from '../components/Table/Table'
import Select from '../components/Select/Select'


export default {
    name: 'Order',
    components: {
        'PreviewImages': PreviewImages,
        'Modal': Modal,
        'Table': Table,
        'Select': Select
    },
    data() {
        return {
            tab: [
                // { name: '添加师傅', status: 0, count: 0 },
                // { name: '邀请师傅', status: 0, count: 0 }
            ],
            breadCrumb: [
                // { name: '商家列表' },
                // { name: '信息收录' },
                // { name: '申请记录' }
            ],
            bread_crumb_selected: 0,
            selected: 0,
            showLoading: false,
            searchValue: '',
            order_id: '',
            preview_images: '',
            showPreviewImages: false,
            remarks: '',
            selected_cities: null,
            cities: [],
            loadAll:false,



            userList: [],
            fields: [
                { key: 'id', label: '编号', width: '70px' },
                { key: 'nickName', label: '昵称', width: '120px' },
                { key: 'avatar', label: '头像', width: '120px' },
                { key: 'appName', label: '渠道', width: '' },
                { key: 'phoneNumber', label: '电话号码', width: '120px' },
                { key: 'city', label: '城市属性', width: '120px' },
                { key: 'createTime', label: '注册时间', width: '220px' },
                { key: 'operate', label: '操作', width: '173px' }
            ],
            role_list: [],
            role_selected: {}

        };
    },
    mounted() {
        // 组件挂载完成回调，处理逻辑
        this.userList_get()
    },
    methods: {
        tabClick(index) {
            this.selected = index
        },
        tabBreadCrumb(index) {
            console.log(index)
            this.bread_crumb_selected = index
            if (index == 0) {
                this.userList_get()
            }
        },

        /************************ 账号权限 *******************************/
        /**获取合作商家列表*/
        table_scrollEvent() {
            console.log('触发事件')
            this.$refs.userList.showLoading()
            this.userList_get(true)
        },
        citySelectChange(item) {
            console.log('城市', this.selected_cities)
            this.loadAll = false
            this.userList_get()
        },
        searchInput(e) {
            if (!this.searchValue) {
                this.userList_get()
            }
        },
        search() {
            this.userList_get()
        },
        userList_get(connect, city = this.selected_cities, key = this.searchValue) {
            if (!connect) {
                this.userList = [];
            }
            console.log('开始获取数据')
            let bread_crumb_selected = this.bread_crumb_selected;
            console.log('userList:', this.userList.length)
            let start_id = this.userList.length > 0 ? this.userList[this.userList.length - 1].id : 0;
            let count = 50;
            this.post('userList_get', { start_id, count, city: city, key: key }).then(({ list, cities }) => {
                setTimeout(() => {
                    if (bread_crumb_selected !== this.bread_crumb_selected) return false;
                    if (!connect) {
                        this.userList = list
                    } else {
                        if (list.length > 0) {
                            //拼接
                            if (this.userList[this.userList.length - 1].id == list[0].id) {
                                list.splice(0, 1)
                                this.userList = this.userList.concat(list)
                            }
                        } else {
                            //已加载全部
                            this.loadAll = true
                        }
                    }
                    this.cities = cities
                }, 100);
            })
        },


        user_delete(item) {
            console.log('用户删除')
            this.$refs.user_delete.show({
                type: 'query',
                title: '删除用户',
                content: '是否确认删除？',
                data: [
                    { name: 'id', label: '编码', value: item.id, required: true, disabled: true }
                ]
            })
        },
        user_delete_submit(item) {
            this.post('user_delete', { id: item.id }).then(({ data }) => {
                this.$toast({ title: '删除成功' })
                this.tabBreadCrumb(0)
            })
        },
        user_edit(item) {
            this.$refs.user_edit.show({
                type: 'form',
                title: '编辑用户信息',
                data: [
                    { name: 'id', label: '编码', value: item.id, placeholder: '', required: true, disabled: true },
                    { name: 'nickName', label: '昵称', value: item.nickName, placeholder: '', disabled: true },
                    { name: 'phoneNumber', label: '电话号码', value: item.phoneNumber, placeholder: '', required: true, maxlength: 11 },
                ]
            })
        },
        user_edit_submit(data) {
            console.log('修改后的数据：', data)
            this.post('user_edit', { ...data }).then(({ data }) => {
                this.$toast({ title: '修改成功' })
                this.tabBreadCrumb(0)
            })
        }
    }
}
</script>
<style scoped >
.wrapper {
    color: #333;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #dddddd;
}

.header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dddddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 15px;
    font-size: 12.5px;
    font-family: "Microsoft YaHei", SimSun;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dddddd;
}

.bread_crumb_item {
    cursor: pointer;
    border-bottom: 2px solid #fff;
    padding: 10px 15px 8px 15px;
    color: #206bc4;
    font-size: 14px;
    margin-right: 4px;

}

.bread_crumb_item_selected {
    cursor: pointer;
    border-bottom: 2px solid #206bc4;
    padding: 10px 15px 8px 15px;
    border-radius: 5px 5px 0 0;
    color: #fff;
    background: #206bc4;
    font-size: 14px;
    margin-right: 4px;

}

.bread_crumb_item_selected:hover {
    background: #206bc4;
}

.bread_crumb_item:hover {
    background: #eeeeee;
}

.body {}

.tab {
    background-color: white;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    user-select: none;

}

.tab-select {
    color: white;
    background-color: #1f68be;
}

.tab:hover {
    background: #e6e6e6;
    color: black;
}

.tab:active {
    background: #e6e6e6;
    color: black;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.tab-border-left {
    border-left: 1px solid #cccccc;
}

.mybtn {
    background-color: #2c72b0;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    user-select: none;

}

.mybtn:hover {
    background-color: #286090;
    color: #ffffff;
}

.mybtn:active {
    background-color: #1d5280;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}


.btn2 {
    background-color: #e8e8e8;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    border: 1px solid #555555;
    color: #000000;
    border-radius: 3px;
    user-select: none;
}

.btn2:hover {
    background-color: #cccccc;
}

.btn2:active {
    background-color: #aaaaaa;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}



.btn3 {
    background-color: #f0ad4e;
    padding: 8px 17px;
    font-size: 14px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    border-radius: 3px;
}

.btn3:hover {
    background-color: #e09b3c;
    color: #ffffff;
}

input {
    outline: none;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    border: 1px solid #cccccc;
}

input:focus {
    border: 1px solid #3873c0 !important;
}

.float {
    /* float: left; */
    /* min-width: 25%; */
    height: 100%;
    display: flex;
    padding: 10px 0;
}


.btn5 {
    background-color: #d9534f;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    border-radius: 3px;

}

.btn5:hover {
    background-color: #c9302c;
    color: #ffffff;
}




.btn6 {
    border: 1px solid #cccccc;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    touch-action: manipulation;
    color: #000;
    border-radius: 3px;
}


.btn6:hover {
    background-color: #eeeeee;
    color: #000;

}

.btn7 {
    border: 1px solid #2c72b0;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    touch-action: manipulation;
    color: #fff;
    border-radius: 3px;
    background-color: #2c72b0;
}
</style>
  