import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from 'store';
import toast from './components/Toast/plugin'
import { get, post } from './utils/request'


Vue.prototype.$cos = {};
Vue.prototype.$store = store;
Vue.prototype.get = get
Vue.prototype.post = post
Vue.use(VueRouter)
Vue.use(toast)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
