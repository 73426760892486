const host = window.webConfig.host;
import axios from 'axios';
import router from '../router/router';
export function get(path = '', data = {}) {
    return new Promise((resolve, reject) => {
        console.log(this.$store.get('token'))
        let url = host + '/web/' + path;
        let token = this.$store.get('token');
        axios({
            url,
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded', 'Authorization': token },
            data: data,
        }).then(res => {
            console.log(path, 'GET返回', res)

            let { success, data, code, msg } = res.data;
            if (success) {
                resolve(data)
            } else {
                switch (code) {
                    case 102:  //登录过期
                        this.$toast({ title: msg })
                        // this.com.app.isLogin = false;
                        this.$store.set('token', null)
                        this.$store.set('admin_acount', null)
                        router.push('/login')
                        break
                    default:
                        reject({ code, msg })
                }
            }
        }).catch(err => {
            console.log('网络错误', err)
        })
    })
}
export function post(path = '', data = {}, headers = {}) {
    return new Promise((resolve, reject) => {
        console.log('xxxxx', this.$store.get('token'))
        let url = host + '/web/' + path;
        let token = this.$store.get('token');
        axios({
            url,
            method: 'POST',
            headers: { 'Authorization': token, ...headers },
            data: data,
        }).then(res => {
            console.log(path, 'post返回', res)
            let { success, data, code, msg } = res.data;
            if (success) {
                resolve(data)
            } else {
                switch (code) {
                    case 102:  //登录过期
                        this.$toast({ title: msg })
                        // this.com.app.isLogin = false;
                        this.$store.set('token', null)
                        this.$store.set('admin_acount', null)
                        router.push('/login')
                        break
                    default:
                        this.$toast({ title: msg })
                        reject({ code, msg })
                }

            }
        }).catch(err => {
            console.log('网络错误', err)
        })
    })
}