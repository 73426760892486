<template>
    <div class="home" style="width: 100vw;height: 100vh;position: relative;">
        <Amap ref="Amap" style="width: 100vw;height: 100vh;"></Amap>
        <div
            style="position: absolute;top: 1.5rem;left: 0;right: 0;margin: auto;border-radius: 3px;padding: 20px 35px;width: 68%;display: flex;flex-direction: column;align-items: start;line-height: 30px;font-size: 14.5px;background-color: #ffffff;box-shadow: 0 0 8px 1px #00000012;">
            <div style="display: flex;align-items: center;flex: 1;width: 100%;position: relative;padding-top: 23px;">
                <div style="position: absolute;left: -15px;top: -10px;font-weight: 600;font-size: 13px;">#{{ data.order_id
                }} {{ data.selectedName
}}</div>
                <span style="margin-right: 65px;">联系人：{{ data.fullName }}</span>
                <span style="margin-right: 65px;">联系电话：{{ data.phone }}</span>
                <span style="margin-right: 65px;">订单金额：{{ data.fee / 100 }}元</span>
                <span style="">下单时间：{{ data.createTimeTxt }}</span>
            </div>
            <div>地址：{{ data.fullAddress }}</div>
            <div>备注：{{ data.remarksContent }}</div>
        </div>
    </div>
</template>
<script>
import Amap from '@/components/Amap/Amap'
export default {
    name: 'map',
    components: {
        Amap
    },
    data() {
        return {
            data: ''
        };
    },
    mounted() {
        let data = JSON.parse(this.$route.query.data);
        let { province, city, district, street_number, detail, location } = data.address;
        data.fullAddress= `${province} ${city} ${district} ${street_number} (${detail}) ${data.houseNumber}`;
        data.remarksContent = data.remarks.content || '无'
        this.data = data;
        console.log(data)
        this.$refs.Amap.initMap({ location: [data.address.location.longitude, data.address.location.latitude] })
    },
    methods: {

    }
}
</script>
<style>
.home {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f9;
    font-family: PingFangSC-Medium, sans-serif;
    --td-brand-color: #333333;
    --td-brand-color-hover: #333333;
    --td-brand-color-focus: #555555;
    --td-brand-color-active: #444444;
    --td-border-level-2-color: #555555;
    --td-component-border: #555555;
    /* --td-brand-color-disabled:#333333;
    --td-brand-color-light: #333333; */
}
</style>